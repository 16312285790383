import React from 'react'
import s from './index.module.scss'

export const Row = ({ withTwoColumns, flex, style, children, className }) => (
  <div
    className={`${flex ? s.rowFlexContainer : s.rowContainer}
      ${withTwoColumns ? s.addColumns : ''}
      ${className}
    `}
    style={style}>
    {children}
  </div>
)

export const DoubleRow = ({ style, children }) => (
  <div className={s.doubleRowContainer} style={style}>
    {children}
  </div>
)

export const Columns = ({ style, children }) => (
  <div className={s.columnsContainer} style={style}>
    {children}
  </div>
)

export const Column = ({ style, children }) => (
  <div className={s.columnContainer} style={style}>
    {children}
  </div>
)

export const Label = ({ children }) => <div className={s.label}>{children}</div>

// Row.propTypes = {
//   flex: PropTypes.bool,
//   style: PropTypes.object,
//   children: PropTypes.node.isRequired
// }

// DoubleRow.propTypes = {
//   style: PropTypes.object,
//   children: PropTypes.node.isRequired
// }
