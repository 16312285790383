import React from 'react'
import { layerOpts } from '../../../constants/options'

export const ColorOverlayLayer = ({ l, visibility }) => {
  const style = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: l.data.color,
    opacity: parseInt(l.data.opacity) / 100,
    zIndex: l.zIndex,
    visibility: visibility,
    pointerEvents: 'none'
  }

  return l.type === layerOpts.OVERLAY && <div style={style} />
}
