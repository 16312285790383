import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  AlignRight,
  AlignCenter,
  AlignLeft,
  AlignBottom,
  AlignMiddle,
  AlignTop
} from '../../../components/Icons'

import { updateProperty } from '../../../actions/layerActions'
import Label from '../../../components/Label'

const Alignment = ({ item, style, ...props }) => {
  const id = item.id
  const { horizontalAlign, verticalAlign } = item.data

  const handleAlignment = e => {
    if (!e.target.name) return

    const name = e.target.name
    const lookup = {
      xright: 'justify-end',
      xcenter: 'justify-center',
      xleft: 'justify-start',
      ytop: 'items-start',
      ybottom: 'items-end',
      ycenter: 'items-center'
    }

    if (name[0] === 'x') {
      props.updateProperty('horizontalAlign', lookup[name], id)
    } else {
      props.updateProperty('verticalAlign', lookup[name], id)
    }
  }

  return (
    <div className='single-row' style={{ alignSelf: 'center', ...style }}>
      <Label text='Alignment' />
      <div className='align_panel--container' style={{ marginLeft: '0px' }}>
        <button
          className={`svg-wrapper ${
            horizontalAlign === 'justify-start' ? 'active' : ''
          }`}
          name='xleft'
          onClick={handleAlignment}>
          <AlignLeft />
        </button>
        <button
          className={`svg-wrapper ${
            horizontalAlign === 'justify-center' ? 'active' : ''
          }`}
          name='xcenter'
          onClick={handleAlignment}>
          <AlignCenter />
        </button>
        <button
          className={`svg-wrapper ${
            horizontalAlign === 'justify-end' ? 'active' : ''
          }`}
          name='xright'
          onClick={handleAlignment}>
          <AlignRight />
        </button>
        <div className='svg-wrapper'></div>
        <button
          className={`svg-wrapper ${
            verticalAlign === 'items-start' ? 'active' : ''
          }`}
          name='ytop'
          onClick={handleAlignment}>
          <AlignTop />
        </button>
        <button
          className={`svg-wrapper ${
            verticalAlign === 'items-center' ? 'active' : ''
          }`}
          name='ycenter'
          onClick={handleAlignment}>
          <AlignMiddle />
        </button>
        <button
          className={`svg-wrapper ${
            verticalAlign === 'items-end' ? 'active' : ''
          }`}
          name='ybottom'
          onClick={handleAlignment}>
          <AlignBottom />
        </button>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProperty
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Alignment)
