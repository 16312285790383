import React from 'react'

import Artboard from '../../containers/Artboard'
import Sidebar from '../../containers/Sidebar'
import Toolbar from '../../containers/Toolbar'
import Help from '../../containers/Help'

const Home = props => {
  return (
    <>
      <Artboard />
      <Sidebar />
      <Toolbar />
      {process.env.REACT_APP_OUTLINE_ACTIVATED === 'true' && <Help />}
    </>
  )
}
export default Home
