import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { AlignRight, AlignCenter, AlignLeft } from '../../../components/Icons'

import { updateProperty } from '../../../actions/layerActions'
import Label from '../../../components/Label'

const TextAlignment = ({
  value,
  style,
  label = 'Text align',
  onClick,
  ...props
}) => {
  return (
    <div className='single-row' style={{ alignSelf: 'center', ...style }}>
      <Label text={label} />
      <div className='align_panel--container' style={{ marginLeft: '0px' }}>
        <button
          className={`svg-wrapper ${value === 'left' ? 'active' : ''}`}
          name='left'
          onClick={onClick}>
          <AlignLeft />
        </button>
        <button
          className={`svg-wrapper ${value === 'center' ? 'active' : ''}`}
          name='center'
          onClick={onClick}>
          <AlignCenter />
        </button>
        <button
          className={`svg-wrapper ${value === 'right' ? 'active' : ''}`}
          name='right'
          onClick={onClick}>
          <AlignRight />
        </button>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProperty
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(TextAlignment)
