import React from 'react'
import s from './index.module.scss'
import Label from '../Label'

export default ({ label, options, data, value, defaultValue, onChange }) => (
  <div className={`${s.selectContainer}`}>
    <Label text={label} />
    <select {...{ onChange, defaultValue }}>
      {options.map(i => (
        <option key={i} value={i}>
          {data[i] ? data[i].name : i}
        </option>
      ))}
    </select>
  </div>
)

export const SelectWrapper = ({
  defaultValue,
  onChange,
  children,
  style,
  ...props
}) => (
  <div className={`${s.selectContainer}`} {...props}>
    <select {...{ onChange, defaultValue }} style={style}>
      {children}
    </select>
  </div>
)
