import React from 'react'
import s from './index.module.scss'

export const Panel = ({ style, children }) => {
  return (
    <>
      <div className={s.panelContainer} style={{ ...style }}>
        {children}
      </div>
    </>
  )
}

export const PanelHeader = ({ style, children }) => {
  return (
    <>
      <div className={s.panelHeader} style={{ ...style }}>
        {children}
      </div>
    </>
  )
}

// Panel.propTypes = {
//   style: PropTypes.object,
//   children: PropTypes.node.isRequired
// }

// PanelHeader.propTypes = {
//   style: PropTypes.object,
//   children: PropTypes.string.isRequired
// }
