import * as types from '../actions/types'

const initialState = {
  hasGrid: false,
  removeBgCreditsLeft: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SETTINGS__TOGGLE_GRID:
      return { ...state, hasGrid: !state.hasGrid }
    case types.SETTINGS__REMOVE_BG_CREDITS_LEFT: {
      return { ...state, removeBgCreditsLeft: action.payload }
    }
    default:
      return state
  }
}
