import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Panel, PanelHeader } from '../Layout/Panel'
import { Row } from '../Layout/Row'
import { templateSettingsOpts } from '../../../constants/options'
import { ButtonWithIcon, ButtonIcon, Text } from '../../../components/ButtonWithIcon'
import { updateArtboardTemplate } from '../../../actions/artboardActions'

const TemplateSection = ({ artboard, updateArtboardTemplate }) => {
  const template = artboard.template

  const handleArtboardChange = e => {
    const currentArtboard = e.target.getAttribute('name')
    updateArtboardTemplate({
      ...templateSettingsOpts[currentArtboard]
    })
  }

  return (
    <Panel>
      <Row>
        <PanelHeader>Template</PanelHeader>
      </Row>
      {Object.keys(templateSettingsOpts).map(t => (
        <ButtonWithIcon
          key={templateSettingsOpts[t].name}
          onClick={handleArtboardChange}
          name={t}
          active={template.name === templateSettingsOpts[t].name}>
          <ButtonIcon>{templateSettingsOpts[t].icon}</ButtonIcon>
          <Text>{templateSettingsOpts[t].name}</Text>
        </ButtonWithIcon>
      ))}
    </Panel>
  )
}

const mapStateToProps = ({ present: l }) => ({
  artboard: l.artboard
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateArtboardTemplate
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSection)
