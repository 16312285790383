import { makeActionCreator } from './_utils'
import * as types from './types'

export const toggleGrid = makeActionCreator(
  types.SETTINGS__TOGGLE_GRID,
  'payload'
)

export const setRemoveBgCreditLeft = makeActionCreator(
  types.SETTINGS__REMOVE_BG_CREDITS_LEFT,
  'payload'
)
