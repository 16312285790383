import React, { useState, useEffect, useRef } from 'react'
import s from './index.module.scss'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Slider from '../../../components/Slider'
import { Close } from '../../../components/Icons'
import * as action from '../../../actions/layerActions'
import { setRemoveBgCreditLeft } from '../../../actions/settingsActions'
import {
  layerOpts,
  colorOpts,
  tearoutLogoOpts,
  tearoutLogos,
  fontOpts,
  brandLogoOpts,
  brandLogos
} from '../../../constants/options'
import Textarea from '../../../components/Textarea'
import ColorPicker from '../../../components/ColorPicker'
import Alignment from '../Alignment'
import Select from '../../../components/Select'
import TextAlignment from '../TextAlignment'
import { Spinner } from '../../../components/Icons'
import { fetchRemoveBgAccount, useRemoveBg } from '../../../utils/removeBg'
import { ButtonWithIcon } from '../../../components/ButtonWithIcon'
import { ButtonIcon } from '../../../components/ButtonWithIcon'
import { Text } from '../../../components/ButtonWithIcon'
import { Overlay } from '../../../components/Icons'
import { TransparentBg } from '../../../components/Icons'
import { Undo } from '../../../components/Icons'

const Settings = ({
  settings,
  isVisible,
  item,
  setClose,
  updateProperty,
  setRemoveBgCreditLeft
}) => {
  return (
    <>
      {isVisible && (
        <div className={s.container}>
          <Header {...{ item, setClose }} />
          <Body
            {...{ item, settings, updateProperty, setRemoveBgCreditLeft }}
          />
        </div>
      )}
    </>
  )
}

const Header = ({ item, setClose }) => (
  <div className={s.header}>
    <div className={s.text}>{item.data.name}</div>
    <div className={s.icon} onClick={() => setClose(false)}>
      <Close />
    </div>
  </div>
)

const Body = ({ item, settings, updateProperty, setRemoveBgCreditLeft }) => {
  const logoUpload = useRef(null)
  const [image, setImage] = useState(null)
  const [isRemoveBgAccountLoading, setRemoveBgAccountLoading] = useState(false)
  const { removeBgCreditsLeft } = settings

  const triggerLogoUpload = () => logoUpload.current.click()

  const handleLogoUpload = ({ target }) => {
    updateProperty('logo', URL.createObjectURL(target.files[0]), item.id)
    target.value = null
  }

  const { removedBgImage, loading, error } = useRemoveBg(
    image,
    item.data.imageType
  )

  useEffect(() => {
    if (removeBgCreditsLeft === null) {
      setRemoveBgAccountLoading(true)
      fetchRemoveBgAccount().then(res => {
        if (res.data) {
          setRemoveBgAccountLoading(false)
          setRemoveBgCreditLeft(
            res.data.attributes.credits.total +
              res.data.attributes.api.free_calls
          )
        }
      })
    }
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_REMOVE_BG === 'true' && removedBgImage) {
      updateProperty('image', removedBgImage, item.id)
    }
  }, [removedBgImage])

  return (
    <div>
      <div className={s.body}>
        {item.type === layerOpts.IMAGE && (
          <>
            {process.env.REACT_APP_REMOVE_BG === 'true' && (
              <>
                {error && <div className={s.error}>{error}</div>}
                <ButtonWithIcon onClick={() => setImage(item.data.image)}>
                  <ButtonIcon>
                    {loading ? <Spinner /> : <TransparentBg />}
                  </ButtonIcon>
                  <Text
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexBasis: '100%',
                      alignItems: 'center'
                    }}>
                    Remove background
                    <span className={s.removeBgCreditsLeft}>
                      {isRemoveBgAccountLoading ? (
                        <Spinner />
                      ) : (
                        `${removeBgCreditsLeft} left`
                      )}
                    </span>
                  </Text>
                </ButtonWithIcon>
                <hr className={s.hr} />
              </>
            )}

            <Slider name='Opacity' min='0' max='100' step='1'
              value={item.data.opacity}
              onChange={e => {
                updateProperty('opacity', e.target.value, item.id)
              }}
            />
            <Slider
              name='Brightness'
              min='0'
              max='200'
              step='1'
              value={item.data.brightness}
              onChange={e => {
                updateProperty('brightness', e.target.value, item.id)
              }}
            />
            <Slider
              name='Contrast'
              min='0'
              max='200'
              step='1'
              value={item.data.contrast}
              onChange={e => {
                updateProperty('contrast', e.target.value, item.id)
              }}
            />
            <Slider
              name='Saturation'
              min='0'
              max='200'
              step='1'
              value={item.data.saturation}
              onChange={e => {
                updateProperty('saturation', e.target.value, item.id)
              }}
            />
            <Slider name='Blur' min='0.0' max='10.0' step='0.5' unit='px'
              value={item.data.blur}
              onChange={e => {
                updateProperty('blur', e.target.value, item.id)
              }}
            />

            <Slider
              name='Ominosity'
              min='0'
              max='100'
              step='1'
              defaultValue='0%'
              value={item.data.clarity}
              onChange={e => {
                const contrast =
                  parseInt(e.target.value) <= 50
                    ? 100
                    : parseInt(item.data.clarity) + 50

                const brightness =
                  parseInt(e.target.value) <= 60
                    ? 100
                    : Math.round((parseInt(item.data.clarity) - 60) / 2 + 100)

                const saturationValue = (10 - parseInt(e.target.value)) * 10
                const saturation = saturationValue <= 0 ? 0 : saturationValue

                updateProperty('clarity', e.target.value, item.id)
                updateProperty('brightness', brightness, item.id)
                updateProperty('contrast', contrast, item.id)
                updateProperty('saturation', saturation, item.id)
              }}
            />
            <div style={{ fontSize: '7px', opacity: '0.5' }}>
              This effect works best at 80-100%. To further increase grittiness,
              bump up the contrast. To make it subtle, either use a lower amount
              or bump it to 100% and lower the contrast. Play around with
              brightness too. :)
            </div>

            <hr className={s.hr} />

            <ButtonWithIcon
              onClick={() => {
                updateProperty('brightness', 100, item.id)
                updateProperty('contrast', 165, item.id)
                updateProperty('saturation', 0, item.id)
                updateProperty('clarity', 100, item.id)
              }}>
              <ButtonIcon>
                <Overlay />
              </ButtonIcon>
              <Text>Negative Preset</Text>
            </ButtonWithIcon>

            <ButtonWithIcon
              onClick={() => {
                updateProperty('brightness', 100, item.id)
                updateProperty('contrast', 100, item.id)
                updateProperty('saturation', 100, item.id)
                updateProperty('blur', 0, item.id)
                updateProperty('clarity', 0, item.id)
              }}>
              <ButtonIcon>
                <Undo />
              </ButtonIcon>
              <Text>Reset</Text>
            </ButtonWithIcon>
          </>
        )}

        {item.type === layerOpts.TEXT && (
          <>
            <Slider
              name='Opacity'
              min='0'
              max='100'
              step='1'
              value={item.data.opacity}
              onChange={e => {
                updateProperty('opacity', e.target.value, item.id)
              }}
            />
            <Textarea
              label='Text'
              value={item.data.text}
              onChange={e => {
                updateProperty('text', e.target.value, item.id)
              }}
            />
            <TextAlignment
              value={item.data.textAlign}
              onClick={e => {
                updateProperty(
                  'textAlign',
                  e.target.getAttribute('name'),
                  item.id
                )
              }}
            />
            <Select
              label='Font'
              options={fontOpts}
              data={fontOpts}
              value={item.data.fontFamily}
              onChange={e => {
                updateProperty('fontFamily', e.target.value, item.id)
              }}
            />
            <Slider
              name='Size'
              defaultValue='0'
              min='12'
              max='100'
              step='1'
              value={item.data.fontSize}
              unit='px'
              onChange={e => {
                updateProperty('fontSize', e.target.value, item.id)
              }}
            />
            <Slider
              name='Leading'
              defaultValue='0'
              min='0.75'
              max='2'
              step='0.05'
              unit=''
              value={item.data.lineHeight}
              onChange={e => {
                updateProperty('lineHeight', e.target.value, item.id)
              }}
            />
            <ColorPicker
              colors={colorOpts}
              activeColor={item.data.color}
              onClick={e => {
                updateProperty('color', e.target.getAttribute('name'), item.id)
              }}
            />
          </>
        )}

        {item.type === layerOpts.OVERLAY && (
          <>
            <Slider
              name='Opacity'
              min='0'
              max='100'
              step='1'
              value={item.data.opacity}
              onChange={e => {
                updateProperty('opacity', e.target.value, item.id)
              }}
            />
            <ColorPicker
              colors={colorOpts}
              activeColor={item.data.color}
              onClick={e => {
                updateProperty('color', e.target.getAttribute('name'), item.id)
              }}
            />
          </>
        )}

        {item.type === layerOpts.TEAROUT && (
          <>
            <input
              type='file'
              style={{ display: 'none' }}
              ref={logoUpload}
              onChange={handleLogoUpload}
            />
            <Select
              label='Logo'
              options={tearoutLogos}
              data={tearoutLogoOpts}
              defaultValue={item.data.logo}
              onChange={e => {
                if (e.target.value === 'custom') {
                  triggerLogoUpload(item.id)
                } else {
                  updateProperty('logo', e.target.value, item.id)
                }
              }}
            />
            <Textarea
              label='Headline'
              value={item.data.text}
              onChange={e => {
                updateProperty('text', e.target.value, item.id)
              }}
            />
            <Textarea
              label='Date/Author'
              value={item.data.date}
              onChange={e => {
                updateProperty('date', e.target.value, item.id)
              }}
            />
            <Slider
              name='Scale'
              min='10'
              max='200'
              step='1'
              value={item.data.scale}
              onChange={e => {
                updateProperty('scale', e.target.value, item.id)
              }}
            />
          </>
        )}

        {item.type === layerOpts.LOGO && (
          <>
            <Alignment item={item} style={{ marginBottom: '12px' }} />
            <Slider
              name='Margin'
              min='0'
              max='100'
              step='1'
              value={item.data.margin}
              unit='px'
              onChange={e => {
                updateProperty('margin', e.target.value, item.id)
              }}
            />
            <Select
              label='Logo'
              options={brandLogos}
              data={brandLogoOpts}
              value={item.data.logo}
              defaultValue={item.data.logo}
              onChange={e => {
                updateProperty('logo', e.target.value, item.id)
                updateProperty(
                  'name',
                  brandLogoOpts[e.target.value].name,
                  item.id
                )
              }}
            />
          </>
        )}

        {item.type === layerOpts.STRAPLINE && (
          <>
            <Textarea
              label='Text'
              value={item.data.text}
              onChange={e => {
                updateProperty('text', e.target.value, item.id)
              }}
            />
            <Select
              label='Font'
              options={fontOpts}
              data={fontOpts}
              value={item.data.fontFamily}
              onChange={e => {
                updateProperty('fontFamily', e.target.value, item.id)
              }}
            />
            <Slider
              name='Size'
              defaultValue='0'
              min='12'
              max='100'
              step='1'
              value={item.data.fontSize}
              unit='px'
              onChange={e => {
                updateProperty('fontSize', e.target.value, item.id)
              }}
            />
            <Slider
              name='Leading'
              defaultValue='0'
              min='0.75'
              max='2'
              step='0.05'
              unit=''
              value={item.data.lineHeight}
              onChange={e => {
                updateProperty('lineHeight', e.target.value, item.id)
              }}
            />
            <TextAlignment
              value={item.data.textAlign}
              style={{ marginBottom: '12px' }}
              onClick={e => {
                updateProperty(
                  'textAlign',
                  e.target.getAttribute('name'),
                  item.id
                )
              }}
            />
            <ColorPicker
              colors={colorOpts}
              label='Text color'
              style={{ marginBottom: '12px' }}
              activeColor={item.data.color}
              onClick={e => {
                updateProperty('color', e.target.getAttribute('name'), item.id)
              }}
            />
            <ColorPicker
              colors={colorOpts}
              label='Background'
              style={{ marginBottom: '12px' }}
              activeColor={item.data.background}
              onClick={e => {
                updateProperty(
                  'background',
                  e.target.getAttribute('name'),
                  item.id
                )
              }}
            />
          </>
        )}

        {item.type === layerOpts.BUTTON && (
          <>
            <Textarea label='Text' value={item.data.text}
              onChange={e => {
                updateProperty('text', e.target.value, item.id)
              }}
            />
            {/* <Select
              label='Symbol'
              options={{

              }}
              data={fontOpts}
              value={item.data.symbol}
              onChange={e => {
                updateProperty('fontFamily', e.target.value, item.id)
              }}
            /> */}
            <Slider name='Margin' min='0' max='200' step='1' unit='px'
              value={item.data.margin}
              onChange={e => {
                updateProperty('margin', e.target.value, item.id)
              }}
            />
            <Slider name='Padding' min='0' max='200' step='1'
              value={item.data.padding}
              onChange={e => {
                updateProperty('padding', e.target.value, item.id)
              }}
            />
            <Slider name='Font Size' min='0' max='200' step='1' unit='px'
              value={item.data.fontSize}
              onChange={e => {
                updateProperty('fontSize', e.target.value, item.id)
              }}
            />
            <Slider name='Border Thickness' min='0' max='50' step='1' unit='px'
              value={item.data.borderWidth}
              onChange={e => {
                updateProperty('borderWidth', e.target.value, item.id)
              }}
            />
            <Slider name='Border Radius' min='0' max='100' step='1' 
              value={item.data.borderRadius}
              onChange={e => {
                updateProperty('borderRadius', e.target.value, item.id)
              }}
            />
            <Slider name='Box Shadow Distance' min='0' max='100' step='1'
              value={item.data.boxShadowDistance}
              onChange={e => {
                updateProperty('boxShadowDistance', e.target.value, item.id)
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ present: l }) => ({
  settings: l.settings
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addImage: action.addImage,
      deleteImage: action.deleteImage,
      toggleImageVisibility: action.toggleImageVisibility,
      reorderImages: action.reorderImages,
      addOverlay: action.addOverlay,
      updateProperty: action.updateProperty,
      setRemoveBgCreditLeft
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
