import React from 'react'
import { Route } from 'react-router-dom'
import Home from './home'
import Auth from './auth'
import { Helmet } from 'react-helmet'

const App = () => (
  <div>
    <Helmet>
      <title>
        Michelangelo
        {process.env.REACT_APP_ORG_NAME !== ''
          ? ` | ${process.env.REACT_APP_ORG_NAME}`
          : ''}
      </title>
    </Helmet>
    <main>
      <Route exact path='/' component={Home} />
      <Route path='/auth' component={Auth} />
    </main>
  </div>
)

export default App
