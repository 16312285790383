import React from 'react'
import s from './index.module.scss'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as action from '../../../../actions/layerActions'
import playbutton from '../../../../components/playbutton.svg'

export const VideoThumbnail = ({ ...props }) => {
  React.useEffect(() => {
    props.addOverlay({ type: 'overlay', color: '#000', opacity: 30 })
    props.addImage({
      type: 'image',
      image: playbutton,
      name: 'Play Button',
      className: s.playButton,
      position: { x: 250, y: 107 }
    })
  }, [])
  return <></>
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addOverlay: action.addOverlay,
      addImage: action.addImage
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(VideoThumbnail)
