import { makeActionCreator } from './_utils'

import * as types from './types'

export const updateArtboardTemplate = makeActionCreator(
  types.ARTBOARD__UPDATE_TEMPLATE,
  'payload'
)

export const updateArtboardZoom = makeActionCreator(
  types.ARTBOARD__UPDATE_ZOOM,
  'payload'
)

export const updateArtboardProperty = makeActionCreator(
  types.ARTBOARD__UPDATE_PROPERTY,
  'property',
  'payload'
)

export const updateArtboardDimension = makeActionCreator(
  types.ARTBOARD__UPDATE_DIMENSION,
  'payload'
)
