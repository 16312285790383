import React from 'react'
import HotKeys from 'react-hot-keys'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { connect } from 'react-redux'
import { Undo, Redo } from '../../../components/Icons'
import s from './index.module.scss'

const UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }) => {
  return (
    <>
      <HotKeys keyName='command+z' onKeyDown={() => canUndo && onUndo()}>
        <button
          onClick={onUndo}
          disabled={!canUndo}
          className={`svg-icon--container ${s.undoRedoIcon}`}>
          <Undo />
        </button>
      </HotKeys>
      <HotKeys keyName='command+shift+z' onKeyDown={() => canRedo && onRedo()}>
        <button
          onClick={onRedo}
          disabled={!canRedo}
          className={`svg-icon--container ${s.undoRedoIcon}`}>
          <Redo />
        </button>
      </HotKeys>
    </>
  )
}

const mapStateToProps = state => {
  return {
    canUndo: state.past.length > 1,
    canRedo: state.future.length > 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUndo: () => dispatch(UndoActionCreators.undo()),
    onRedo: () => dispatch(UndoActionCreators.redo())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UndoRedo)
