import React from 'react'
import s from './index.module.scss'

export const ButtonWithIcon = ({ children, active, ...props }) => (
  <div
    className={`${s.container} ${active ? s.active : ''}`}
    role='button'
    onClick={props.onClick}
    {...props}>
    {React.Children.map(children, child => React.cloneElement(child))}
  </div>
)

export const ButtonIcon = ({ children }) => (
  <div className={s.iconContainer}>{children}</div>
)

export const Text = ({ children, style }) => (
  <p className={s.text} style={style}>
    {children}
  </p>
)
