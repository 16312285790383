import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as Icon from '../../../components/Icons'
import { Panel, PanelHeader } from '../Layout/Panel'
import { Row } from '../Layout/Row'
import { ButtonWithIcon, ButtonIcon, Text } from '../../../components/ButtonWithIcon'
import { getBlobUrl } from '../../../utils/utils'
import {
  addImage,
  addOverlay,
  addLogo,
  addTearout,
  addText,
  addStrapline,
  addButton,
} from '../../../actions/layerActions'

const AddSection = ({
  addImage,
  addOverlay,
  addTearout,
  addText,
  addLogo,
  addStrapline,
  addButton,
}) => {
  const handleImageUpload = async e => {
    if (e.target.files)
      addImage({
        name: e.target.files[0].name,
        image: getBlobUrl(e.target.files[0]),
        imageType: e.target.files[0].type
      })

    e.target.value = null
  }

  return (
    <>
      <Panel>
        <Row>
          <PanelHeader>Add</PanelHeader>
        </Row>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <label>
            <input
              className='file_upload--input'
              type='file'
              onChange={handleImageUpload}
            />
            <ButtonWithIcon>
              <ButtonIcon>
                <Icon.Image />
              </ButtonIcon>
              <Text>Image</Text>
            </ButtonWithIcon>
          </label>

          <ButtonWithIcon onClick={() => addText()}>
            <ButtonIcon>
              <Icon.Text />
            </ButtonIcon>
            <Text>Text</Text>
          </ButtonWithIcon>

          <ButtonWithIcon onClick={() => addTearout()}>
            <ButtonIcon>
              <Icon.Tearout />
            </ButtonIcon>
            <Text>Tearout</Text>
          </ButtonWithIcon>

          <ButtonWithIcon onClick={() => addOverlay()}>
            <ButtonIcon>
              <Icon.Overlay />
            </ButtonIcon>
            <Text>Overlay</Text>
          </ButtonWithIcon>

          <ButtonWithIcon onClick={() => addLogo()}>
            <ButtonIcon>
              <Icon.LogoPlaceholder />
            </ButtonIcon>
            <Text>Logo</Text>
          </ButtonWithIcon>

          <ButtonWithIcon onClick={() => addStrapline()}>
            <ButtonIcon>
              <Icon.LogoPlaceholder />
            </ButtonIcon>
            <Text>Strapline</Text>
          </ButtonWithIcon>

          <ButtonWithIcon onClick={() => addButton()}>
            <ButtonIcon>
              <Icon.LogoPlaceholder />
            </ButtonIcon>
            <Text>Button</Text>
          </ButtonWithIcon>
        </div>
      </Panel>
    </>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addImage,
      addOverlay,
      addLogo,
      addTearout,
      addText,
      addStrapline,
      addButton,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(AddSection)
