import React from 'react'
import s from './index.module.scss'

import { connect } from 'react-redux'

import { ColorOverlayLayer } from '../../Layers/ColorOverlayLayer'
import { TearoutLayer } from '../../Tearout'
import { ImageLayer } from '../../Layers/ImageLayer'
import { TextLayer } from '../../Layers/TextLayer'
import { LogoLayer } from '../../Layers/LogoLayer'
import { StraplineLayer } from '../../Layers/StraplineLayer'

const SplitImage = ({ layers, artboard }) => {
  const numOfImages = layers.length
  const leftImgIndex = numOfImages - 1
  const rightImgIndex = numOfImages - 2
  const data = artboard.data

  const getLeftImage = () => {
    if (numOfImages === 0) {
      return <div className={`${s.placeholder} ${s.left}`}></div>
    }

    const l = layers[leftImgIndex]
    const visibility = l.isVisible ? null : 'hidden'

    return (
      <>
        <ColorOverlayLayer {...{ l, visibility }} />
        <ImageLayer {...{ l, visibility }} />
      </>
    )
  }

  const getRightImage = () => {
    if (numOfImages <= 1) {
      return <div className={`${s.placeholder} ${s.right}`}></div>
    }

    const l = layers[rightImgIndex]
    const visibility = l.isVisible ? null : 'hidden'

    return (
      <div
        className={s.resizableContainer}
        style={{
          transform: `skew(${-data.splitAngle}deg)`
        }}>
        <ColorOverlayLayer {...{ l, visibility }} />
        <ImageLayer {...{ l, visibility }} />
      </div>
    )
  }

  return (
    <>
      <div className={s.splitImageContainer}>
        <ImageLeftContainer>{getLeftImage()}</ImageLeftContainer>
        <ImageRightContainer
          style={{
            right: -data.splitPosition + '%',
            transform: `skew(${data.splitAngle}deg)`
          }}>
          {getRightImage()}
        </ImageRightContainer>
      </div>

      {numOfImages > 2 && (
        <>
          {layers.slice(0, numOfImages - 2).map(l => {
            const visibility = l.isVisible ? null : 'hidden'
            return (
              <React.Fragment key={l.id}>
                <ColorOverlayLayer {...{ l, visibility }} />
                <ImageLayer {...{ l, visibility }} />
                <TearoutLayer {...{ l, visibility }} />
                <TextLayer {...{ l, visibility }} />
                <LogoLayer {...{ l, visibility }} />
                <StraplineLayer {...{ l, visibility }} />
              </React.Fragment>
            )
          })}
        </>
      )}
    </>
  )
}

const ImageLeftContainer = ({ children }) => (
  <div className={s.imageLeftContainer}>{children}</div>
)

const ImageRightContainer = ({ children, style }) => (
  <div className={s.imageRightContainer} style={style}>
    {children}
  </div>
)

const mapStateToProps = ({ present: l }) => ({
  artboard: l.artboard,
  layers: l.layers
})
export default connect(mapStateToProps, null)(SplitImage)
