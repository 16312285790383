import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as Icon from '../../../components/Icons'

import { Panel, PanelHeader } from '../Layout/Panel'
import { Row } from '../Layout/Row'
import { dimensionSettingsOpts } from '../../../constants/options'
import { ButtonWithIcon, ButtonIcon, Text } from '../../../components/ButtonWithIcon'
import { updateArtboardDimension } from '../../../actions/artboardActions'

const DimensionSection = ({ artboard, updateArtboardDimension }) => {
  const dimension = artboard.dimension

  const initialCustomDimension = {
    id: 'Custom',
    name: 'Custom',
    height: '1000',
    width: '1000'
  }

  const [isCustom, setIsCustom] = useState(false)
  const [customDimension, setCustomDimension] = useState(initialCustomDimension)

  const handleArtboardChange = e => {
    setIsCustom(false)

    const currentArtboard = e.target.getAttribute('name')
    updateArtboardDimension({
      ...dimensionSettingsOpts[currentArtboard]
    })
  }

  const handleCustomDimensionChange = e => {
    setCustomDimension(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value
      }
    })
  }

  useEffect(() => {
    isCustom && updateArtboardDimension(customDimension)
  }, [customDimension, isCustom])

  const s = {
    input: {
      fontSize: '12px',
      padding: '8px',
      height: '32px',
      background: 'var(--gray-800)',
      border: '1px solid var(--gray-650)',
      borderRadius: '2px',
      width: '72px',
      marginRight: '8px'
    },
    unit: {
      fontSize: '12px',
      position: 'absolute',
      zIndex: '1',
      right: '16px',
      top: '6px',
      opacity: 0.3
    },
    dimension: {
      wrapper: { display: 'flex', alignItems: 'center' },
      span: {
        marginLeft: 'auto',
        fontSize: '10px',
        opacity: '0.5',
        fontWeight: 400
      }
    }
  }

  const getDimensions = () => {
    return isCustom
      ? `${customDimension.width} x ${customDimension.height}px`
      : `${dimension.width} x ${dimension.height}px`
  }

  return (
    <Panel>
      <Row>
        <PanelHeader style={s.dimension.wrapper}>
          Size
          <span style={s.dimension.span}>{getDimensions()}</span>
        </PanelHeader>
      </Row>
      <div>
        {Object.keys(dimensionSettingsOpts).map(t => (
          <ButtonWithIcon
            key={dimensionSettingsOpts[t].name}
            onClick={handleArtboardChange}
            name={t}
            active={dimension.id === dimensionSettingsOpts[t].id}>
            <ButtonIcon>{dimensionSettingsOpts[t].icon}</ButtonIcon>
            <Text>{dimensionSettingsOpts[t].name}</Text>
          </ButtonWithIcon>
        ))}
      </div>
      <ButtonWithIcon onClick={() => setIsCustom(true)} active={isCustom}>
        <ButtonIcon>
          <Icon.Custom />
        </ButtonIcon>
        <Text>Custom</Text>
      </ButtonWithIcon>
      {isCustom && (
        <div style={{ display: 'flex', marginTop: '8px' }}>
          <div style={{ position: 'relative' }}>
            <input
              type='text'
              placeholder='Width'
              name='width'
              value={customDimension.width}
              onChange={handleCustomDimensionChange}
              style={s.input}
            />
            <span style={s.unit}>px</span>
          </div>
          <div style={{ position: 'relative' }}>
            <input
              type='text'
              placeholder='Height'
              name='height'
              value={customDimension.height}
              onChange={handleCustomDimensionChange}
              style={s.input}
            />
            <span style={s.unit}>px</span>
          </div>
        </div>
      )}
    </Panel>
  )
}

const mapStateToProps = ({ present: l }) => ({
  artboard: l.artboard
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateArtboardDimension
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(DimensionSection)
