import React from 'react'
import s from './index.module.scss'

import { Resize } from '../Resize'
import RenderText from '../../../components/RenderText'

import { tearoutLogoOpts, layerOpts } from '../../../constants/options'
import tearoutImage from '../../../assets/images/tearout.png'

export const TearoutLayer = ({ l, visibility, ...props }) =>
  l.type === layerOpts.TEAROUT && (
    <div
      className={s.tearouts}
      style={{
        transform: `scale(${l.data.scale / 100})`,
        zIndex: l.zIndex,
        visibility: visibility
      }}>
      <Resize lockAspectRatio={0}>
        <div className={s.tearoutContainer} key={l.id} {...props}>
          <img src={tearoutImage} className={s.tearout} alt='' />
          <div className={s.contentContainer}>
            <img
              src={
                tearoutLogoOpts[l.data.logo]
                  ? tearoutLogoOpts[l.data.logo].image
                  : l.data.logo
              }
              className={s.logo}
              alt=''
            />
            <img src={l.data.path} alt='' />
            <RenderText data={{ ...l.data, highlightBg: '#fff100' }} />
            <div className={s.source}>{l.data.date}</div>
          </div>
        </div>
      </Resize>
    </div>
  )
