import React from 'react'

export default ({ text, value, unit = '%' }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '12px',
      marginBottom: '8px',
      color: 'var(--gray-400)',
      letterSpacing: 0
    }}>
    <div>{text}</div>
    {value && (
      <div>
        {value}
        {unit}
      </div>
    )}
  </div>
)
