import * as types from '../actions/types'
import { layerOpts } from '../constants/options'
import { v4 as uuid } from 'uuid'
const e = process.env

const initialState = [
  {
    id: '3668288b-f820-4c8d-a571-4f9a4af05b6c',
    type: 'overlay',
    zIndex: 10,
    isVisible: true,
    data: {
      name: 'Background',
      color: 'black',
      opacity: 100
    }
  }
]

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LAYER__DELETE:
      return state.filter(s => s.id !== action.payload.id)

    case types.LAYER__TOGGLE_VISIBILITY:
      return state.map(s =>
        s.id === action.payload.id ? { ...s, isVisible: !s.isVisible } : s
      )

    case types.LAYER__REORDER:
      const result = Array.from(action.images)
      const [removed] = result.splice(action.startIndex, 1)
      result.splice(action.endIndex, 0, removed)
      const reordered = result.map((r, i) => ({
        ...r,
        zIndex: (action.images.length - i) * 10
      }))
      return reordered

    case types.LAYER__ADD_IMAGE:
      return [
        {
          id: uuid(),
          type: layerOpts.IMAGE,
          zIndex: (state.length + 1) * 10,
          isVisible: true,
          data: {
            position: { x: 0, y: 0 },
            opacity: 100,
            brightness: 100,
            contrast: 100,
            saturation: 100,
            blur: 0,
            clarity: 0,
            ...action.payload
          }
        },
        ...state
      ]

    case types.LAYER__ADD_OVERLAY:
      return [
        {
          id: uuid(),
          type: layerOpts.OVERLAY,
          zIndex: (state.length + 1) * 10,
          isVisible: true,
          data: {
            name: 'Overlay',
            color: '#000000',
            opacity: 30,
            ...action.payload
          }
        },
        ...state
      ]

    case types.LAYER__ADD_TEAROUT:
      return [
        {
          id: uuid(),
          type: layerOpts.TEAROUT,
          zIndex: (state.length + 1) * 10,
          isVisible: true,
          data: {
            logo: e.REACT_APP_TEAROUT_LOGO_DEFAULT,
            image: '',
            text: e.REACT_APP_TEAROUT_TEXT_DEFAULT,
            date: e.REACT_APP_TEAROUT_DATE_DEFAULT,
            scale: 100,
            opacity: 100,
            ...action.payload
          }
        },
        ...state
      ]

    case types.LAYER__ADD_LOGO:
      return [
        {
          id: uuid(),
          type: layerOpts.LOGO,
          zIndex: (state.length + 1) * 10,
          isVisible: true,
          data: {
            name: e.REACT_APP_BRAND_LOGO_DEFAULT,
            margin: e.REACT_APP_BRAND_LOGO_MARGIN_DEFAULT,
            logo: e.REACT_APP_BRAND_LOGO_DEFAULT.toLowerCase(),
            opacity: 100,
            horizontalAlign: 'justify-end',
            verticalAlign: 'items-end',
            ...action.payload
          }
        },
        ...state
      ]

    case types.LAYER__ADD_TEXT:
      return [
        {
          id: uuid(),
          type: layerOpts.TEXT,
          zIndex: (state.length + 1) * 10,
          isVisible: true,
          data: {
            text: e.REACT_APP_TEXT_DEFAULT,
            fontFamily: e.REACT_APP_FONT_DEFAULT,
            fontSize: '50',
            lineHeight: '1.15',
            color: e.REACT_APP_PRIMARY_TEXT_COLOR,
            opacity: 100,
            textAlign: 'left',
            ...action.payload
          }
        },
        ...state
      ]

    case types.LAYER__ADD_STRAPLINE:
      return [
        {
          id: uuid(),
          type: layerOpts.STRAPLINE,
          zIndex: (state.length + 1) * 10,
          isVisible: true,
          data: {
            name: 'Strapline',
            color: e.REACT_APP_PRIMARY_TEXT_COLOR,
            opacity: 100,
            background: e.REACT_APP_PRIMARY_BACKGROUND_COLOR,
            fontSize: '40',
            fontFamily: e.REACT_APP_FONT_DEFAULT,
            fontWeight: 'bold',
            lineHeight: '1.1',
            position: 'top',
            textAlign: 'center',
            text: e.REACT_APP_STRAPLINE_DEFAULT,
            ...action.payload
          }
        },
        ...state
      ]

    case types.LAYER__ADD_BUTTON:
      return [
        {
          id: uuid(),
          type: layerOpts.BUTTON,
          zIndex: (state.length + 1) * 10,
          isVisible: true,
          data: {
            name: 'Button',
            color: e.REACT_APP_PRIMARY_TEXT_COLOR,
            opacity: 100,
            background: e.REACT_APP_PRIMARY_BACKGROUND_COLOR,
            fontFamily: e.REACT_APP_FONT_DEFAULT,
            fontStyle: 'italic',
            fontWeight: '800',
            textAlign: 'center',
            text: e.REACT_APP_STRAPLINE_DEFAULT,
            margin: 50,
            padding: 50,
            fontSize: 32,
            borderRadius: 40,
            borderWidth: 2,
            boxShadowDistance: 30,
            ...action.payload
          }
        },
        ...state
      ]
  

    case types.LAYER__UPDATE_PROPERTY:
      return state.map(s =>
        s.id === action.id
          ? {
              ...s,
              data: {
                ...s.data,
                [action.property]: action.payload
              }
            }
          : s
      )

    default:
      return state
  }
}
