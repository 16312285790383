import React from 'react'
import { Resize } from '../Resize'
import { layerOpts } from '../../../constants/options'

export const StraplineLayer = ({ l, visibility }) => {
  const { data } = l

  const position = data.position === 'top' ? { top: 0 } : { bottom: 0 }

  const box = {
    visibility,
    zIndex: l.zIndex,
    background: data.background,
    fontSize: data.fontSize + 'px',
    fontFamily: data.fontFamily,
    fontWeight: data.fontWeight,
    color: data.color,
    opacity: data.opacity,
    lineHeight: data.lineHeight,
    textAlign: data.textAlign,
    position: 'relative',
    ...position,
    width: '600px',
    height: 'fit-content',
    padding: '16px'
    // textTransform: 'uppercase'
  }

  return (
    l.type === layerOpts.STRAPLINE && (
      <Resize
        style={{ visibility, zIndex: l.zIndex }}
        dragAxis='y'
        noResizeHandles
        bounds='parent'
        position={{ x: 0, y: 40 }}>
        <div style={box}>{data.text}</div>
      </Resize>
    )
  )
}
