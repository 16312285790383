import { useState, useEffect } from 'react'
import { b64toBlob, getBlobUrl, objectUrlToB64 } from './utils'

export const useRemoveBg = (image, type) => {
  const [removedBgImage, setRemovedBgImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchRemoveBg = async () => {
      try {
        setLoading(true)
        const b64 = await objectUrlToB64(image)

        const url = 'https://api.remove.bg/v1.0/removebg'
        const body = {
          image_file_b64: b64,
          size: 'preview'
        }
        await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_REMOVE_BG_API_KEY,
            Accept: 'application/json'
          },
          body: JSON.stringify(body)
        })
          .then(res => res.json())
          .then(res => {
            if (res.errors) {
              setError(res.errors[0].detail)
              return
            }

            const { data } = res
            const contentType = type
            const blob = b64toBlob(data.result_b64, contentType)
            setRemovedBgImage(getBlobUrl(blob))
          })
      } catch (err) {
        console.log('Err', err)
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    image && fetchRemoveBg()
  }, [image])

  return { removedBgImage, loading, error }
}

export const fetchRemoveBgAccount = async () => {
  const url = 'https://api.remove.bg/v1.0/account'
  const response = await fetch(url, {
    headers: {
      'X-API-Key': process.env.REACT_APP_REMOVE_BG_API_KEY
    }
  })
  const res = await response.json()
  return res
}
