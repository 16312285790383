import { makeActionCreator } from './_utils'
import * as types from './types'

export const addImage = makeActionCreator(types.LAYER__ADD_IMAGE, 'payload')

export const addOverlay = makeActionCreator(types.LAYER__ADD_OVERLAY, 'payload')

export const addLogo = makeActionCreator(types.LAYER__ADD_LOGO, 'payload')

export const addTearout = makeActionCreator(types.LAYER__ADD_TEAROUT, 'payload')

export const addText = makeActionCreator(types.LAYER__ADD_TEXT, 'payload')

export const addButton = makeActionCreator(types.LAYER__ADD_BUTTON, 'payload')

export const addStrapline = makeActionCreator(
  types.LAYER__ADD_STRAPLINE,
  'payload'
)

export const updateProperty = makeActionCreator(
  types.LAYER__UPDATE_PROPERTY,
  'property',
  'payload',
  'id'
)

export const deleteImage = makeActionCreator(types.LAYER__DELETE, 'payload')

export const toggleImageVisibility = makeActionCreator(
  types.LAYER__TOGGLE_VISIBILITY,
  'payload'
)

export const reorderImages = makeActionCreator(
  types.LAYER__REORDER,
  'images',
  'startIndex',
  'endIndex'
)
