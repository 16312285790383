import React from 'react'
import { layerOpts, brandLogoOpts } from '../../../constants/options'

export const LogoLayer = ({ l, visibility }) => {
  const logoContainer = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1100,
    top: 0,
    left: 0,
    display: 'flex',
    padding: l.data.margin + 'px',
    pointerEvents: 'none'
  }

  const logo = {
    maxWidth: '84px'
  }

  return (
    l.type === layerOpts.LOGO && (
      <div
        style={{
          ...logoContainer,
          zIndex: l.zIndex,
          visibility: visibility
        }}
        className={`${l.data.verticalAlign} ${l.data.horizontalAlign}`}>
        <img src={brandLogoOpts[l.data.logo].image} style={logo} alt='' />
      </div>
    )
  )
}
