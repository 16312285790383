import React from 'react'
import * as Icon from '../components/Icons'

const customTemplateSettings =
  process.env.REACT_APP_CUSTOM_TEMPLATE === 'true'
    ? {
        [process.env.REACT_APP_CUSTOM_TEMPLATE_NAME]: {
          id: process.env.REACT_APP_CUSTOM_TEMPLATE_NAME,
          name: process.env.REACT_APP_CUSTOM_TEMPLATE_NAME,
          height: process.env.REACT_APP_CUSTOM_TEMPLATE_HEIGHT,
          width: process.env.REACT_APP_CUSTOM_TEMPLATE_WIDTH
        }
      }
    : {}

export const templateOpts = {
  SPLITIMAGE: 'splitimage',
  VIDEOTHUMBNAIL: 'videothumbnail'
}

export const templateSettingsOpts = {
  [templateOpts.SPLITIMAGE]: {
    id: templateOpts.SPLITIMAGE,
    name: 'Split Image',
    height: '314px',
    width: '600px',
    icon: <Icon.SplitImage />
  },
  [templateOpts.VIDEOTHUMBNAIL]: {
    id: templateOpts.VIDEOTHUMBNAIL,
    name: 'Video Thumbnail',
    height: '314px',
    width: '600px',
    icon: <Icon.Video />
  },
  ...customTemplateSettings
}

export const dimensionOpts = {
  FACEBOOKSHARE: 'facebookshare',
  SQUARE: 'square',
  INSTAPOST: 'instapost',
  INSTASTORY: 'instastory',
  EMAILBANNER: 'emailbanner'
}

export const dimensionSettingsOpts = {
  [dimensionOpts.SQUARE]: {
    id: dimensionOpts.SQUARE,
    name: 'Facebook Square',
    height: '1200',
    width: '1200',
    icon: <Icon.Facebook />
  },
  [dimensionOpts.FACEBOOKSHARE]: {
    id: dimensionOpts.FACEBOOKSHARE,
    name: 'Facebook Share',
    height: '628',
    width: '1200',
    icon: <Icon.Facebook />
  },
  [dimensionOpts.INSTAPOST]: {
    id: dimensionOpts.INSTAPOST,
    name: 'Instagram Post',
    height: '1350',
    width: '1080',
    icon: <Icon.Instagram />
  },
  [dimensionOpts.INSTASTORY]: {
    id: dimensionOpts.INSTASTORY,
    name: 'Instagram Story',
    height: '1920',
    width: '1080',
    icon: <Icon.Instagram />
  },
  [templateOpts.EMAILBANNER]: {
    id: dimensionOpts.EMAILBANNER,
    name: 'Email Banner',
    height: '400',
    width: '1200',
    icon: <Icon.Email />
  }
}

export const layerOpts = {
  IMAGE: 'image',
  OVERLAY: 'overlay',
  LOGO: 'logo',
  TEXT: 'text',
  TEAROUT: 'tearout',
  STRAPLINE: 'strapline',
  BUTTON: 'button',
}

// prettier-ignore
export const fontOpts = process.env.REACT_APP_FONT_OPTIONS
  .split(',')
  .map(i => i.trim())

// prettier-ignore
export const colorOpts = process.env.REACT_APP_COLORS
  .split(',')
  .map(i =>i.trim())

// prettier-ignore
export const tearoutLogoOpts = process.env.REACT_APP_TEAROUT_LOGOS
  .split(',')
  .reduce((obj, cur) => {
    const str = cur.split('|')
    const name = str[0]
    const key = name.toLowerCase().replace(/\s+/g, '')
    const image = process.env.REACT_APP_TEAROUT_HOST + str[1]
    obj[key] = { name, image }
    return {
      ...obj,
      'custom': {
        name: '[Upload your own]',
        image: ''
      }
    }
  }, {})

export const tearoutLogos = Object.keys(tearoutLogoOpts).sort()

// prettier-ignore
export const brandLogoOpts = process.env.REACT_APP_BRAND_LOGOS
  .split(',')
  .reduce((obj, cur) => {
    const str = cur.split('|')
    const name = str[0]
    const key = name.toLowerCase().replace(/\s+/g, '')
    const image = process.env.REACT_APP_BRAND_LOGOS_HOST + str[1]
    obj[key] = { name, image }
    return obj
  }, {})

export const brandLogos = Object.keys(brandLogoOpts).sort()
