import React from 'react'
import { Resize } from '../Resize'
import { layerOpts } from '../../../constants/options'
import RenderText from '../../../components/RenderText'

export const TextLayer = ({ l, visibility }) =>
  l.type === layerOpts.TEXT && (
    <Resize
      style={{
        zIndex: l.zIndex,
        visibility: visibility
      }}
      position={l.position}>
      <div
        className={`flex h-full w-full`}
        style={{
          fontFamily: l.data.font,
          opacity: l.data.opacity / 100,
          justifyContent: l.data.textAlign,
        }}>
        <RenderText data={l.data} />
      </div>
    </Resize>
  )
