import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import htmltoimage from '../../utils/html-to-image'
import style from './index.module.scss'
import UndoRedo from './UndoRedo'
import { toggleGrid } from '../../actions/settingsActions'

import {
  ShowGrid,
  Download,
  Hamburger,
  Spinner,
  SlackLogo,
  Send,
  Tick
} from '../../components/Icons'
import { updateArtboardZoom } from '../../actions/artboardActions'
import { SelectWrapper } from '../../components/Select'

const Toolbar = ({ settings, artboard, toggleGrid, updateArtboardZoom }) => {
  const { zoom } = artboard

  const handlePngDownload = e => {
    e.preventDefault()
    htmltoimage
      .toPng(document.getElementById('node'), {
        scale: 1 / zoom,
        quality: 1
      })
      .then(function (dataUrl) {
        var link = document.createElement('a')
        link.download = 'image.png'
        link.href = dataUrl
        link.click()
      })
  }

  const handleZoomChange = e => {
    const value = e.target.value <= 0 ? 0.01 : e.target.value
    updateArtboardZoom(value)
  }

  return (
    <>
      <div className={style.toolbar}>
        <div className={style.toolbarLeft}>
          <Dropdown />
          <UndoRedo />
          <button
            className={`svg-icon--container ${style.showGridIcon}
          ${settings.hasGrid ? style.active : null}
          `}
            onClick={() => toggleGrid()}>
            <ShowGrid />
          </button>
          <Zoom zoom={zoom} onChange={handleZoomChange} />
          <button onClick={handlePngDownload} className={style.downloadButton}>
            <Download />
            Download
          </button>
        </div>
        <div className={style.toolbarRight}>
          {process.env.REACT_APP_SLACK_ACTIVATED === 'true' && (
            <SignInWithSlack />
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ present: l }) => ({
  settings: l.settings,
  artboard: l.artboard
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleGrid,
      updateArtboardZoom
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)

const Zoom = ({ zoom, onChange }) => {
  const zoomValues = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.8, 1.0, 1.2]
  return (
    <div style={{ margin: '0 4px' }}>
      <SelectWrapper
        defaultValue={zoom}
        style={{ height: '24px', marginBottom: '0' }}
        onChange={onChange}>
        {zoomValues.map(i => (
          <option key={i} value={i}>
            {i * 100}%
          </option>
        ))}
      </SelectWrapper>
    </div>
  )
}

const Dropdown = () => {
  const node = useRef(null)

  const [open, setOpen] = useState(false)
  const menuItems = process.env.REACT_APP_MENU_ITEMS
    ? process.env.REACT_APP_MENU_ITEMS.split(',').map(i => ({
        title: i.split('|')[0],
        link: i.split('|')[1]
      }))
    : ''
  const [menu] = useState(menuItems)

  const handleClick = e => {
    if (node.current && node.current.contains(e.target)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <>
      {menu !== '' && (
        <div ref={node}>
          <button
            className={`svg-icon--container ${style.dropdownIcon}
        ${open ? style.active : null}
        `}
            onClick={() => setOpen(!open)}>
            <Hamburger />
          </button>
          {open && (
            <ul className={style.dropdownMenu}>
              {menu.map(i => (
                <li className={style.dropdownItem} key={i.title}>
                  <a href={i.link} target='_blank' rel='noopener noreferrer'>
                    {i.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  )
}

const SignInWithSlack = () => {
  const [success, setSuccess] = useState('access_token' in localStorage)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [isReplyingToThread, setIsReplyingToThread] = useState(false)

  const url = 'https://slack.com/oauth/authorize'
  const scope = '?scope=chat:write:user,files:write:user'
  const client_id = `&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}`
  const redirect_uri =
    process.env.NODE_ENV === 'development'
      ? '&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth'
      : `&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}%2Fauth`

  const final_url = url + scope + client_id + redirect_uri

  const channel =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SLACK_CHANNEL_TEST
      : process.env.REACT_APP_SLACK_CHANNEL_PROD

  useEffect(() => {
    setSuccess('access_token' in localStorage)
    setIsRedirecting(false)
  }, [])

  const postMessage = () => {
    if (isReplyingToThread && 'thread_ts' in localStorage) {
      replyToThread()
      return
    }

    const token = localStorage.getItem('access_token')

    htmltoimage
      .toBlob(document.getElementById('node'), {
        scale: 2,
        quality: 1
      })
      .then(data => {
        setIsSending(true)
        const url = `https://slack.com/api/files.upload?token=${token}`
        const body = new FormData()
        body.append('file', data)
        body.append('channels', channel)
        body.append('filename', 'image.png')

        fetch(url, { method: 'POST', body })
          .then(r => r.json())
          .then(j => {
            if (!j.ok) setSuccess(false)
            setIsSending(false)
            setIsDone(true)
            const thread_id = j.file.shares.public[j.file.channels[0]][0].ts
            localStorage.setItem('thread_ts', thread_id)

            setTimeout(() => {
              setIsDone(false)
            }, 2000)
          })
      })
  }

  const replyToThread = () => {
    const token = localStorage.getItem('access_token')
    const thread_ts = localStorage.getItem('thread_ts')

    htmltoimage
      .toBlob(document.getElementById('node'), {
        scale: 2,
        quality: 1
      })
      .then(data => {
        setIsSending(true)
        const url = `https://slack.com/api/files.upload?token=${token}`
        const body = new FormData()
        body.append('file', data)
        body.append('channels', channel)
        body.append('filename', 'image.png')
        body.append('thread_ts', thread_ts)

        fetch(url, { method: 'POST', body })
          .then(r => r.json())
          .then(j => {
            if (!j.ok) setSuccess(false)
            setIsSending(false)
            setIsDone(true)
            setTimeout(() => {
              setIsDone(false)
            }, 2000)
          })
      })
  }

  const renderSending = () => (
    <>
      <Spinner />
      <b>Sending...</b>
    </>
  )
  const renderDone = () => (
    <>
      <Tick />
      <b>Sent!</b>
    </>
  )

  const renderSendForReview = () => (
    <>
      <Send />
      <b>Send for review</b>
    </>
  )

  return success ? (
    <>
      {'thread_ts' in localStorage && (
        <label className={style.replyToPreviousThread}>
          <input
            type='checkbox'
            checked={isReplyingToThread}
            onChange={() => setIsReplyingToThread(!isReplyingToThread)}
          />
          Reply to previous thread
        </label>
      )}
      <div
        className={`${style.buttonWithIcon} ${style.sendStatus}`}
        onClick={() => postMessage()}>
        {isSending ? renderSending() : null}
        {isDone ? renderDone() : null}
        {!isSending && !isDone ? renderSendForReview() : null}
      </div>
    </>
  ) : (
    <a
      className={` ${style.buttonWithIcon}`}
      href={final_url}
      onClick={() => setIsRedirecting(true)}>
      {isRedirecting ? <Spinner /> : <SlackLogo />}
      <b>Log in</b>
    </a>
  )
}
