import React from 'react'
import s from './index.module.scss'
import Label from '../Label'

export default ({
  name,
  value,
  defaultValue,
  min,
  max,
  step,
  onChange,
  style,
  className = '',
  unit = '%'
}) => (
  <div className={`${s.slider} ${className}`} style={style}>
    <Label text={name} value={value} unit={unit} />
    <input
      type='range'
      className={s.sliderInput}
      {...{ min, max, step, value, onChange }}
    />
  </div>
)
