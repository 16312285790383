import React from 'react'
import s from './index.module.scss'

import Label from '../Label'

export default ({ onClick, label = 'Colors', colors, activeColor, style }) => (
  <>
    <Label text={label} />
    <div className={s.container}>
      {colors &&
        colors.map(color => (
          <div
            key={color}
            className={`${s.color} ${color === activeColor ? s.active : ''}`}
            style={{ background: color, ...style }}
            name={color}
            onClick={onClick}></div>
        ))}
    </div>
  </>
)
