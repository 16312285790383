import React from 'react'

import AddSection from './Section/AddSection'
import TemplateSection from './Section/TemplateSection'
import { LayerSection } from './Section/LayerSection'
import DimensionSection from './Section/DimensionSection'

const Sidebar = () => {
  return (
    <>
      <div className='sidebar--container'>
        <DimensionSection />
        <TemplateSection />
        <AddSection />
        <LayerSection />
      </div>
    </>
  )
}

export default Sidebar
