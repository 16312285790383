import React from 'react'
import s from './index.module.scss'

import { connect } from 'react-redux'

import { templateOpts } from '../../../constants/options'

const LayerInfo = ({ artboard, item }) => {
  return (
    <>
      {artboard.template.id === templateOpts.SPLITIMAGE && (
        <div className={s.layerInfo}>
          {item.zIndex === 10 && 'LEFT'}
          {item.zIndex === 20 && 'RIGHT'}
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ present: l }) => ({
  artboard: l.artboard
})

export default connect(mapStateToProps, null)(LayerInfo)
