import React from 'react'
import { connect } from 'react-redux'
import { updateArtboardProperty } from '../../actions/artboardActions'
import { bindActionCreators } from 'redux'

import { templateOpts } from '../../constants/options'

import SplitImage from './Templates/SplitImage'
import VideoThumbnail from './Templates/VideoThumbnail'

import { ColorOverlayLayer } from './Layers/ColorOverlayLayer'
import { TearoutLayer } from './Tearout'
import { ImageLayer } from './Layers/ImageLayer'
import { TextLayer } from './Layers/TextLayer'
import { LogoLayer } from './Layers/LogoLayer'
import { StraplineLayer } from './Layers/StraplineLayer'
import { ButtonLayer } from './Layers/ButtonLayer'
import Slider from '../../components/Slider'

const Artboard = ({ artboard, settings, layers, updateArtboardProperty }) => {
  const templateId = artboard.template && artboard.template.id
  const dimensions = {
    height: artboard.dimension.height * artboard.zoom + 'px',
    width: artboard.dimension.width * artboard.zoom + 'px'
  }

  const renderLayers = () => (
    <>
      {layers.map(l => {
        const visibility = l.isVisible ? null : 'hidden'
        return (
          <React.Fragment key={l.id}>
            <ColorOverlayLayer {...{ l, visibility }} />
            <ImageLayer {...{ l, visibility }} />
            <TearoutLayer {...{ l, visibility }} />
            <TextLayer {...{ l, visibility }} />
            <LogoLayer {...{ l, visibility }} />
            <StraplineLayer {...{ l, visibility }} />
            <ButtonLayer {...{ l, visibility }} />
          </React.Fragment>
        )
      })}
    </>
  )

  const renderTemplate = () => {
    switch (templateId) {
      case templateOpts.SPLITIMAGE:
        return <SplitImage />
      case templateOpts.VIDEOTHUMBNAIL:
        return <VideoThumbnail />
      default:
        return
    }
  }

  return (
    <div className='artboard--container' id='artboard'>
      <div className='artboard--inner' style={{ ...dimensions }}>
        {settings.hasGrid && <ShowGrid />}

        <div className={`h-full`} id='node'>
          <div className={`overflow-container`}>
            {/* Split image has it's own layer renderer */}
            {templateId !== templateOpts.SPLITIMAGE && renderLayers()}
            {renderTemplate()}
          </div>
        </div>
        {templateId === templateOpts.SPLITIMAGE && (
          <>
            <Slider
              name='Position'
              min='0'
              max='100'
              step='1'
              value={artboard.data.splitPosition}
              onChange={e => {
                updateArtboardProperty('splitPosition', e.target.value)
              }}
              style={{
                marginTop: '24px'
              }}
            />
            <Slider
              name='Angle'
              min='-60'
              max='60'
              step='1'
              unit='deg'
              value={artboard.data.splitAngle}
              onChange={e => {
                updateArtboardProperty('splitAngle', e.target.value)
              }}
              style={{
                marginTop: '24px'
              }}
            />
          </>
        )}
      </div>
    </div>
  )
}

const ShowGrid = () => (
  <div className='facebook-grid'>
    {[...Array(25)].map((e, i) => (
      <div key={i}></div>
    ))}
  </div>
)

const mapStateToProps = ({ present: l }) => ({
  layers: l.layers,
  artboard: l.artboard,
  settings: l.settings
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateArtboardProperty
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Artboard)
