import * as types from '../actions/types'
import { dimensionOpts, dimensionSettingsOpts } from '../constants/options'

const initialState = {
  dimension: dimensionSettingsOpts[dimensionOpts.FACEBOOKSHARE],
  zoom: 0.5,
  template: {},
  data: {
    splitPosition: 50,
    splitAngle: -30
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ARTBOARD__UPDATE_TEMPLATE:
      return { ...state, template: action.payload }

    case types.ARTBOARD__UPDATE_ZOOM:
      return { ...state, zoom: action.payload }

    case types.ARTBOARD__UPDATE_PROPERTY:
      return {
        ...state,
        data: {
          ...state.data,
          [action.property]: action.payload
        }
      }

    case types.ARTBOARD__UPDATE_DIMENSION:
      return {
        ...state,
        dimension: action.payload
      }

    default:
      return state
  }
}
