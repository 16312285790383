import React from 'react'

import Layers from '../Layers'
import { Panel, PanelHeader } from '../Layout/Panel'
import { Row } from '../Layout/Row'

export const LayerSection = () => (
  <>
    <Panel>
      <Row>
        <PanelHeader>Layers</PanelHeader>
      </Row>
      <Layers />
    </Panel>
  </>
)
