import React, { useEffect, useState } from 'react'
import queryString from 'querystring'
import { Redirect } from 'react-router'

export const Auth = props => {
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (
      localStorage.getItem('access_token') === 'undefined' ||
      localStorage.getItem('access_token') === '' ||
      localStorage.getItem('access_token') === null ||
      localStorage.hasOwnProperty('access_token')
    ) {
      getCode()
    } else {
      setSuccess(true)
    }
  }, [])

  const getCode = () => {
    let params = queryString.parse(props.location.search.slice(1))
    if (!params.code) {
      return
    }
    const url = 'https://slack.com/api/oauth.access'
    const client_id = `?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}`
    const client_secret = `&client_secret=${process.env.REACT_APP_SLACK_CLIENT_SECRET}`
    const code = '&code=' + params.code
    const final_url = url + client_id + client_secret + code

    setIsLoading(true)

    fetch(final_url)
      .then(res => res.json())
      .then(data => {
        setIsLoading(false)

        if (data.ok) {
          setSuccess(true)
          localStorage.setItem('access_token', data.access_token)
        } else {
          setSuccess(false)
        }
      })
  }

  const renderSuccess = () => <Redirect to='/' />

  const renderLoading = () => <h1 style={{ color: 'white' }}>Loading...</h1>

  const renderFailed = () => <h1 style={{ color: 'white' }}>Failed</h1>

  return (
    <>
      {success ? renderSuccess() : null}
      {isLoading ? renderLoading() : null}
      {!isLoading && !success ? renderFailed() : null}
    </>
  )
}

export default Auth
