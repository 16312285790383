import { combineReducers } from 'redux'
import * as types from '../actions/types'
import artboardReducer from './artboardReducer'
import settingsReducer from './settingsReducer'
import layerReducer from './layerReducer'
import undoable, { excludeAction } from 'redux-undo'

export default undoable(
  combineReducers({
    artboard: artboardReducer,
    settings: settingsReducer,
    layers: layerReducer
  }),
  {
    filter: excludeAction(types.SETTINGS__TOGGLE_GRID)
  }
)
