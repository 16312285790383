export const ARTBOARD__UPDATE_TEMPLATE = 'ARTBOARD__UPDATE_TEMPLATE'
export const ARTBOARD__UPDATE_ZOOM = 'ARTBOARD__UPDATE_ZOOM'
export const ARTBOARD__UPDATE_DIMENSION = 'ARTBOARD__UPDATE_DIMENSION'
export const ARTBOARD__UPDATE_PROPERTY = 'ARTBOARD__UPDATE_PROPERTY'

// LAYER
export const LAYER__ADD_IMAGE = 'LAYER__ADD_IMAGE'
export const LAYER__ADD_LOGO = 'LAYER__ADD_LOGO'
export const LAYER__ADD_TEAROUT = 'LAYER__ADD_TEAROUT'
export const LAYER__ADD_TEXT = 'LAYER__ADD_TEXT'
export const LAYER__ADD_OVERLAY = 'LAYER__ADD_OVERLAY'
export const LAYER__ADD_STRAPLINE = 'LAYER__ADD_STRAPLINE'
export const LAYER__ADD_BUTTON = 'LAYER__ADD_BUTTON'

export const LAYER__UPDATE_PROPERTY = 'LAYER__UPDATE_PROPERTY'
export const LAYER__DELETE = 'LAYER__DELETE'
export const LAYER__TOGGLE_VISIBILITY = 'IMAGE__TOGGLE_VISIBILITY'
export const LAYER__REORDER = 'LAYER__REORDER'

// GRID
export const SETTINGS__TOGGLE_GRID = 'SETTINGS__TOGGLE_GRID'
export const SETTINGS__REMOVE_BG_CREDITS_LEFT =
  'SETTINGS__REMOVE_BG_CREDITS_LEFT'
