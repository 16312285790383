import React from 'react'
import { Resize } from '../Resize'
import { layerOpts } from '../../../constants/options'

export const ImageLayer = ({ l, visibility }) => {
  const clarityStyles = {
    overlay: {
      height: '100%',
      position: 'absolute',
      zIndex: 2,
      overflow: 'hidden',
      mixBlendMode: 'overlay',
      width: '100%',
      opacity: l.data.opacity / 100
    },
    image: {
      opacity: l.data.clarity / 100,
      mixBlendMode: 'hard-light',
      filter: `
        blur(4px)
        invert(1)
        contrast(0.75)
        saturate(${l.data.saturation / 100})
      `
    }
  }

  const imageStyles = {
    opacity: l.data.opacity / 100,
    zIndex: 1,
    filter: `
        brightness(${l.data.brightness / 100})
        contrast(${l.data.contrast / 100})
        saturate(${l.data.saturation / 100})
        blur(${l.data.blur}px)
      `
  }

  return (
    l.type === layerOpts.IMAGE && (
      <Resize
        style={{
          zIndex: l.zIndex,
          visibility: visibility
        }}
        position={l.data.position}>
        {l.data.clarity > 0 && (
          <div style={clarityStyles.overlay}>
            <img src={l.data.image} style={clarityStyles.image} alt='' />
          </div>
        )}
        <img src={l.data.image} style={imageStyles} alt='' />
      </Resize>
    )
  )
}
