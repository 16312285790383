import React from 'react'
import style from './index.module.scss'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as action from '../../../actions/layerActions'
import LayerRow from '../LayerRow'

const Layers = ({ layers, artboard, ...props }) => {
  function onDragEnd (result) {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return
    }
    props.reorderImages(layers, result.source.index, result.destination.index)
  }

  const handleDelete = item => {
    props.deleteImage(item)
  }

  const handleVisibility = item => {
    props.toggleImageVisibility(item)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={layers.length > 0 ? style.layerContainer : null}>
            {layers &&
              layers.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {provided => (
                    <LayerRow
                      {...{
                        provided,
                        item,
                        handleVisibility,
                        handleDelete,
                        artboard
                      }}
                    />
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const mapStateToProps = ({ present: l }) => ({
  layers: l.layers,
  artboard: l.artboard
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteImage: action.deleteImage,
      toggleImageVisibility: action.toggleImageVisibility,
      reorderImages: action.reorderImages
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Layers)
