import React from 'react'
import s from './index.module.scss'
import Markdown from 'markdown-to-jsx'

const RenderText = ({ data, style }) => {
  const textStyles = {
    zIndex: 1200,
    color: data.color || 'black',
    fontSize: (data.fontSize || '28') + 'px',
    lineHeight: data.lineHeight || '1.15',
    fontFamily: data.fontFamily || process.env.REACT_APP_FONT_DEFAULT,
    display: 'inline-block',
    pointerEvents: 'none',
    fontWeight: 700,
    height: '100%'
  }

  const Highlight = ({
    color = data.highlightBg || process.env.REACT_APP_PRIMARY_BACKGROUND_COLOR,
    children
  }) => {
    return (
      <>
        {children && (
          <span
            className={`
              ${s.highlightBg}
              ${s[textStyles.fontFamily.replace(' ', '').toLowerCase()]}
            `}
            style={{
              '--highlight-color': color,
              fontFamily: data.fontFamily,
              ...style
            }}>
            {children}
          </span>
        )}
      </>
    )
  }

  return (
    <div style={textStyles}>
      <Markdown
        style={{ height: 'inherit' }}
        options={{
          forceInline: true,
          overrides: {
            hl: { component: Highlight },
            Hl: { component: Highlight },
            HL: { component: Highlight }
          }
        }}>
        {data.text}
      </Markdown>
    </div>
  )
}

export default RenderText
