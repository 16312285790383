import React from 'react'
import { layerOpts } from '../../../constants/options'

export const ButtonLayer = ({ l, visibility }) => {
  const ButtonContainer = {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1100,
    top: 0,
    left: 0,
    display: 'flex',
    paddingBottom: l.data.margin + 'px',
    pointerEvents: 'none',
    textAlign: 'center',
  }

  return (
    l.type === layerOpts.BUTTON && (
      <div
        style={{
          ...ButtonContainer,
          zIndex: l.zIndex,
          visibility: visibility
        }}
        className={'items-end justify-center'}>
        <div style={{
          background: l.data.background,
          color: l.data.color,
          fontFamily: l.data.fontFamily,
          fontWeight: l.data.fontWeight,
          fontSize: l.data.fontSize + 'px',
          fontStyle: l.data.fontStyle,
          padding: `${l.data.padding / 100}em ${1.5 * l.data.padding / 100}em`,
          paddingBottom: `${0.9 * l.data.padding / 100}em`,
          borderColor: '#FFFFFF',
          borderStyle: 'solid',
          borderRadius: (l.data.borderRadius / 100) + 'em',
          borderWidth: l.data.borderWidth + 'px',
          boxShadow: `0 ${l.data.fontSize * l.data.boxShadowDistance / 100}px 0 #b1bff3`,
          textTransform: 'uppercase',
        }}>{ l.data.text }</div>
      </div>
    )
  )
}
