import React, { useState } from 'react'
import s from './index.module.scss'
import { Rnd } from 'react-rnd'

export const Resize = ({
  style,
  children,
  noHandles,
  noResizeHandles,
  position,
  dragAxis = 'both',
  ...props
}) => {
  const [pos, setPos] = useState({
    height: 'auto',
    width: 'auto',
    x: position ? position.x : 0,
    y: position ? position.y : 0
  })
  const [internalStyle, setInternalStyle] = useState({})
  const [handleComponent, setHandleComponent] = useState({})

  const handleMouseEnter = () => {
    if (noHandles) return

    setInternalStyle({
      boxShadow: '0px 0px 0px 2px var(--primary-color)'
    })

    if (!noResizeHandles) {
      setHandleComponent({
        left: <ResizeHandle />,
        right: <ResizeHandle />,
        top: <ResizeHandle />,
        bottom: <ResizeHandle />,
        topLeft: <ResizeHandle />,
        topRight: <ResizeHandle />,
        bottomLeft: <ResizeHandle />,
        bottomRight: <ResizeHandle />
      })
    }
  }

  const handleMouseLeave = () => {
    if (noHandles) return

    setInternalStyle({
      boxShadow: 'none'
    })
    setHandleComponent({})
  }

  const ResizeHandle = () => (
    <div className={s.resizeHandleContainer}>
      <div className={s.resizeHandle}></div>
    </div>
  )

  return (
    <>
      <Rnd
        size={{ width: pos.width, height: pos.height }}
        position={{ x: pos.x, y: pos.y }}
        style={{
          ...style,
          ...internalStyle,
          width: '100%'
        }}
        lockAspectRatio={props.lockAspectRatio ? props.lockAspectRatio : true}
        onDragStop={(e, d) => {
          setPos({
            ...pos,
            x: dragAxis === 'y' ? pos.x : d.x,
            y: dragAxis === 'x' ? pos.y : d.y
          })
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          setPos({
            height: ref.style.height,
            width: ref.style.width,
            ...position
          })
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        resizeHandleComponent={handleComponent}
        dragAxis={dragAxis}
        {...props}>
        {children}
      </Rnd>
    </>
  )
}
