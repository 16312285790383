import React from 'react'
import s from './index.module.scss'

import {
  Eye,
  Trash,
  Preferences,
  Text,
  LogoPlaceholder,
  Tearout,
} from '../../../components/Icons'
import { layerOpts } from '../../../constants/options'
import Settings from '../Settings'
import LayerInfo from '../LayerInfo'

export default ({
  provided,
  item,
  artboard,
  handleVisibility,
  handleDelete
}) => {
  const [isSettingsVisible, setSettingsVisible] = React.useState(false)

  return (
    <>
      <Container provided={provided}>
        <LayerInfo item={item} />
        <div className={s.inner}>
          <Button
            onClick={() => handleVisibility(item)}
            icon={<Eye />}
            isVisible={item.isVisible}
          />
          {getLayer(item.type, item)}
        </div>
        <div className={s.inner}>
          <Button
            onClick={() => setSettingsVisible(!isSettingsVisible)}
            icon={<Preferences />}
            active={isSettingsVisible}
          />
          <Button onClick={() => handleDelete(item)} icon={<Trash />} />
        </div>
      </Container>
      <Settings
        isVisible={isSettingsVisible}
        item={item}
        setClose={setSettingsVisible}
      />
    </>
  )
}

const getLayer = (type, item) => {
  switch (type) {
    case layerOpts.OVERLAY:
      return <OverlayLayer item={item} />
    case layerOpts.LOGO:
      return <LogoLayer item={item} />
    case layerOpts.TEAROUT:
      return <TearoutLayer item={item} />
    case layerOpts.TEXT:
      return <TextLayer item={item} />
    case layerOpts.IMAGE:
      return <ImageLayer item={item} />
    case layerOpts.STRAPLINE:
      return <StraplineLayer item={item} />
    case layerOpts.BUTTON:
      return <ButtonLayer item={item} />
    default:
      return
  }
}

const OverlayLayer = ({ item }) => (
  <>
    <Thumbnail background={item.data.color} />
    <LayerName name={item.data.name} />
  </>
)

const TearoutLayer = ({ item }) => (
  <>
    <div className={s.thumbnail}>
      <Tearout />
    </div>
    <LayerName name={item.data.text} />
  </>
)

const TextLayer = ({ item }) => (
  <>
    <div className={s.thumbnail}>
      <Text />
    </div>
    <LayerName name={item.data.text} />
  </>
)

const ImageLayer = ({ item }) => (
  <>
    <img src={item.data.image} className={s.thumbnail} alt='' />
    <LayerName name={item.data.name} />
  </>
)

const LogoLayer = ({ item }) => (
  <>
    <div className={s.thumbnail}>
      <LogoPlaceholder />
    </div>
    <LayerName name={item.data.name} />
  </>
)

const StraplineLayer = ({ item }) => (
  <>
    <Thumbnail background={item.data.background} />
    <LayerName name={item.data.name} />
  </>
)

const ButtonLayer = ({ item }) => (
  <>
    <Thumbnail background={item.data.background} />
    <LayerName name={item.data.name} />
  </>
)

const Container = ({ children, provided }) => (
  <div
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    className={s.layerContainer}>
    {children}
  </div>
)

const LayerName = ({ name }) => <p className={s.layerName}>{name}</p>

const Thumbnail = ({ background }) => (
  <div className={s.thumbnail} style={{ background: background }}></div>
)

const Button = ({ children, isVisible = true, active = false, ...props }) => (
  <div className={`${s.icon} ${active && s.active}`} {...props}>
    {isVisible && props.icon}
  </div>
)
