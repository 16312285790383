import React from 'react'
import s from './index.module.scss'
import Label from '../Label'

export default ({ label, value, onChange, className }) => (
  <>
    <Label text={label} />
    <textarea
      className={`${s.textarea} ${className}`}
      {...{ value, onChange }}></textarea>
  </>
)
