import React from 'react'

export const AlignRight = ({ className }) => (
  <svg
    className={className}
    width='13'
    height='14'
    viewBox='0 0 13 14'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 13.5h1V.5h-1v13zm-12-8h10v-2H0v2zm10 5H4v-2h6v2z'
      fillRule='nonzero'
      fillOpacity='1'
      stroke='none'
      className='align-icon--path'></path>
  </svg>
)

export const AlignCenter = ({ className }) => (
  <svg
    width='12'
    height='14'
    viewBox='0 0 12 14'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.5.5h-1v3h-5v2h5v3h-3v2h3v3h1v-3h3v-2h-3v-3h5v-2h-5v-3z'
      fillRule='nonzero'
      fillOpacity='1'
      stroke='none'
      className='align-icon--path'></path>
  </svg>
)

export const AlignLeft = ({ className }) => (
  <svg
    className={className}
    width='13'
    height='14'
    viewBox='0 0 13 14'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 13.5H0V.5h1v13zm12-8H3v-2h10v2zm-10 5h6v-2H3v2z'
      fillRule='nonzero'
      fillOpacity='1'
      stroke='none'
      className='align-icon--path'></path>
  </svg>
)

export const AlignBottom = () => (
  <svg
    width='14'
    height='13'
    viewBox='0 0 14 13'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.5 0v10h-2V0h2zm8 12v1H.5v-1h13zm-3-2V4h-2v6h2z'
      fillRule='nonzero'
      fillOpacity='1'
      stroke='none'
      className='align-icon--path'></path>
  </svg>
)

export const AlignMiddle = () => (
  <svg
    width='14'
    height='12'
    viewBox='0 0 14 12'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.5 5.5v-5h2v5h3v-3h2v3h3v1h-3v3h-2v-3h-3v5h-2v-5h-3v-1h3z'
      fillRule='nonzero'
      fillOpacity='1'
      stroke='none'
      className='align-icon--path'></path>
  </svg>
)

export const AlignTop = () => (
  <svg
    width='14'
    height='13'
    viewBox='0 0 14 13'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5 1V0H.5v1h13zm-8 12V3h-2v10h2zm5-10v6h-2V3h2z'
      fillRule='nonzero'
      fillOpacity='1'
      stroke='none'
      className='align-icon--path'></path>
  </svg>
)

export const Grid = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
    <title>grid</title>
    <path
      fill='#fff'
      className='align-icon--path'
      d='M19.45,149.53h29v27.22c0,8.59,6.83,14.32,14.55,14.32a14.12,14.12,0,0,0,14.21-14.32V149.53H122.7v27.22a14.38,14.38,0,0,0,28.76,0V149.53h29.2a14.05,14.05,0,0,0,14.21-14.32c0-7.61-5.62-14.44-14.21-14.44h-29.2V80.88h29.2a14.05,14.05,0,0,0,14.21-14.32c0-7.72-5.62-14.44-14.21-14.44h-29.2V23.25A14.08,14.08,0,0,0,137.13,8.93c-7.71,0-14.43,5.62-14.43,14.32V52.12H77.19V23.25A14.06,14.06,0,0,0,63,8.93c-7.72,0-14.55,5.62-14.55,14.32V52.12h-29c-8.7,0-14.32,6.72-14.32,14.44A14.08,14.08,0,0,0,19.45,80.88h29v39.89h-29a14.38,14.38,0,0,0,0,28.76Zm57.74-28.76V80.88H122.7v39.89Z'
    />
  </svg>
)

export const Trash = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 10.28 12'>
    <title>noun_Delete_1537734</title>
    <polygon
      fill='#fff'
      points='9.25 12 1.03 12 1.03 2.42 1.86 2.42 1.86 11.17 8.42 11.17 8.42 2.42 9.25 2.42 9.25 12'
    />
    <polygon
      fill='#fff'
      points='7.42 2.43 6.59 2.43 6.59 0.83 3.69 0.83 3.69 2.42 2.86 2.42 2.86 0 7.42 0 7.42 2.43'
    />
    <rect fill='#fff' y='2.01' width='10.28' height='0.83' />
  </svg>
)

export const Add = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.5 5.5v-5h1v5h5v1h-5v5h-1v-5h-5v-1h5z'
      fillRule='nonzero'
      fillOpacity='1'
      fill='#fff'
      stroke='none'></path>
  </svg>
)

export const Eye = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8 10c1.105 0 2-.895 2-2 0-1.105-.895-2-2-2-1.104 0-2 .895-2 2 0 1.105.896 2 2 2z'
      fillRule='nonzero'
      fillOpacity='1'
      fill='#fff'
      stroke='none'></path>
    <path
      d='M8 4c2.878 0 5.378 1.621 6.635 4-1.257 2.379-3.757 4-6.635 4-2.878 0-5.377-1.621-6.635-4C2.623 5.621 5.122 4 8 4zm0 7c-2.3 0-4.322-1.194-5.478-3C3.678 6.194 5.7 5 8 5c2.3 0 4.322 1.194 5.479 3C12.322 9.806 10.3 11 8 11z'
      fillRule='evenodd'
      fillOpacity='1'
      fill='#fff'
      stroke='none'></path>
  </svg>
)

export const Undo = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 17.14 20'>
    <title>Untitled-1</title>
    <polygon
      points='17.14 5.93 17.14 20 3.91 20 3.91 18.49 15.63 18.49 15.63 7.45 2.89 7.45 7.76 12.3 6.69 13.37 0 6.69 6.69 0 7.76 1.07 2.89 5.93 17.14 5.93'
      fill='#fff'
    />
  </svg>
)

export const Redo = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 17.14 20'>
    <title>Untitled-1</title>
    <polygon
      points='0 5.93 0 20 13.23 20 13.23 18.49 1.51 18.49 1.51 7.45 14.25 7.45 9.38 12.3 10.45 13.37 17.14 6.69 10.45 0 9.38 1.07 14.25 5.93 0 5.93'
      fill='#fff'
    />
  </svg>
)

export const ShowGrid = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 16v-3H0v-1h3V4H0V3h3V0h1v3h8V0h1v3h3v1h-3v8h3v1h-3v3h-1v-3H4v3H3zm9-4V4H4v8h8z'
      fillRule='evenodd'
      fillOpacity='1'
      fill='#fff'
      stroke='none'></path>
  </svg>
)

export const Download = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16.69 20'>
    <title>noun_Download_3111830</title>
    <polygon
      fill='white'
      points='16.28 9.01 8.34 16.94 0.4 9.01 1.48 7.93 7.58 14.03 7.58 0 9.1 0 9.1 14.03 15.21 7.93 16.28 9.01'
    />
    <rect fill='white' y='18.48' width='16.69' height='1.52' />
  </svg>
)

export const Hamburger = () => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 13H2v1h14v-1zm0-5H2v1h14V8zm0-5H2v1h14V3z'
      fillRule='nonzero'
      fillOpacity='1'
      fill='#fff'
      stroke='none'></path>
  </svg>
)

export const SlackLogo = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 20'>
    <title>slack-new-logo</title>
    <path
      d='M7.33,0a2,2,0,0,0,0,4h2V2a2,2,0,0,0-2-2h0m0,5.33H2a2,2,0,0,0,0,4H7.33a2,2,0,0,0,0-4Z'
      transform='translate(0)'
      fill='#fff'
    />
    <path
      d='M20,7.33a2,2,0,1,0-4,0v2h2A2,2,0,0,0,20,7.33Zm-5.33,0V2a2,2,0,0,0-4,0V7.33a2,2,0,0,0,4,0Z'
      transform='translate(0)'
      fill='#fff'
    />
    <path
      d='M12.67,20a2,2,0,1,0,0-4h-2v2A2,2,0,0,0,12.67,20Zm0-5.33H18a2,2,0,0,0,0-4H12.67a2,2,0,0,0,0,4Z'
      transform='translate(0)'
      fill='#fff'
    />
    <path
      d='M0,12.67a2,2,0,0,0,4,0v-2H2A2,2,0,0,0,0,12.67Zm5.33,0V18a2,2,0,0,0,4,0V12.67a2,2,0,0,0-4,0h0'
      transform='translate(0)'
      fill='#fff'
    />
  </svg>
)

export const Send = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 19.48 19.48'>
    <title>slack-new-logo</title>
    <polygon
      points='19.48 0 12.09 19.48 7.32 13.37 14.03 5.45 6.11 12.16 0 7.39 19.48 0'
      fill='#fff'
    />
  </svg>
)

export const Spinner = () => (
  <svg
    version='1.1'
    id='loader-1'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='16px'
    height='16px'
    viewBox='0 0 50 50'>
    <path
      fill='#FFF'
      d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'>
      <animateTransform
        attributeType='xml'
        attributeName='transform'
        type='rotate'
        from='0 25 25'
        to='360 25 25'
        dur='0.6s'
        repeatCount='indefinite'
      />
    </path>
  </svg>
)

export const Tick = () => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 20 14.92'>
    <title>noun_done_3129517</title>
    <polygon
      points='6.54 12 1.46 6.92 0 8.38 6.54 14.92 20 1.46 18.54 0 6.54 12'
      fill='#fff'
    />
  </svg>
)

export const PlayButton = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 1.6C23.9403 1.6 30.4 8.05973 30.4 16C30.4 23.9403 23.9403 30.4 16 30.4C8.05973 30.4 1.6 23.9403 1.6 16C1.6 8.05973 8.05973 1.6 16 1.6ZM16 0C7.16427 0 0 7.16373 0 16C0 24.8363 7.16427 32 16 32C24.8357 32 32 24.8363 32 16C32 7.16373 24.8357 0 16 0Z'
      fill='white'
    />
    <path
      d='M21.9247 14.2175L13.6095 8.46085C12.1744 7.46754 11 8.13394 11 9.94095V22.0561C11 23.8631 12.1759 24.5317 13.6137 23.5423L21.921 17.8234C23.3583 16.8328 23.3598 15.2109 21.9247 14.2175ZM20.8164 16.6154L13.4325 21.6986C12.9536 22.0288 12.5616 21.8059 12.5616 21.2036V10.793C12.5616 10.1906 12.9531 9.96888 13.4315 10.3002L20.818 15.4135C21.2964 15.7442 21.2959 16.2852 20.8164 16.6154Z'
      fill='white'
    />
  </svg>
)

export const Text = () => (
  <svg
    width='16'
    height='13'
    viewBox='0 0 16 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0H1H10H11V1V3H10V1H6V12H8V13H3V12H5V1H1V3H0V1V0Z'
      fill='white'
    />
    <rect x='10' y='6' width='6' height='1' fill='white' />
    <rect x='13' y='3' width='3' height='1' fill='white' />
    <rect x='10' y='9' width='6' height='1' fill='white' />
    <rect x='10' y='12' width='6' height='1' fill='white' />
  </svg>
)

export const Image = () => (
  <svg
    width='11'
    height='10'
    viewBox='0 0 11 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 3.2C8.66274 3.2 9.2 2.66274 9.2 2C9.2 1.33726 8.66274 0.8 8 0.8C7.33726 0.8 6.8 1.33726 6.8 2C6.8 2.66274 7.33726 3.2 8 3.2ZM8 4C9.10457 4 10 3.10457 10 2C10 0.89543 9.10457 0 8 0C6.89543 0 6 0.89543 6 2C6 3.10457 6.89543 4 8 4Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.875 6.5L4.125 3L0 10H11L8.25 5.8L6.875 6.5ZM8.01462 6.70245L6.68535 7.37917L4.20582 4.22341L1.21426 9.3H9.71539L8.01462 6.70245Z'
      fill='white'
    />
  </svg>
)

export const Overlay = () => (
  <svg
    width='16'
    height='14'
    viewBox='0 0 16 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.39999 7.5L0 9L7.99999 14L16 9L13.6 7.5L12.6566 8.08963L14.1132 9L7.99999 12.8208L1.8868 9L3.34339 8.08963L2.39999 7.5Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.99999 1.17925L1.8868 5L7.99999 8.82075L14.1132 4.99999L7.99999 1.17925ZM7.99999 0L0 5L7.99999 10L16 4.99999L7.99999 0Z'
      fill='white'
    />
  </svg>
)

export const Facebook = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5339 0C11.3431 0 12 0.656953 12 1.46611V10.5339C12 11.343 11.343 12 10.5339 12H8.03855V7.47855H9.59948L9.89648 5.54213H8.03855V4.28548C8.03855 3.75572 8.29807 3.23932 9.13027 3.23932H9.975V1.59073C9.975 1.59073 9.20836 1.4599 8.47542 1.4599C6.94523 1.4599 5.94511 2.3873 5.94511 4.06624V5.54213H4.24418V7.47855H5.94511V12H1.46611C0.656953 12 0 11.343 0 10.5339V1.46611C0 0.656953 0.65693 0 1.46611 0H10.5339V0Z'
      fill='white'
    />
  </svg>
)

export const Instagram = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.99962 2.91997C4.29893 2.91997 2.9187 4.2991 2.9187 5.99844C2.9187 7.69777 4.29893 9.0769 5.99962 9.0769C7.70031 9.0769 9.07736 7.69777 9.07736 5.99844C9.08054 4.2991 7.70031 2.91997 5.99962 2.91997ZM5.99962 7.99896C3.35657 7.99896 3.35339 4.00425 5.99962 4.00425C8.64267 4.00108 8.64584 7.99896 5.99962 7.99896Z'
      fill='white'
    />
    <path
      d='M9.92122 2.7995C9.92122 3.1958 9.59758 3.51918 9.20097 3.51918C8.80435 3.51918 8.48071 3.1958 8.48071 2.7995C8.48071 2.4032 8.80435 2.07982 9.20097 2.07982C9.60076 2.07982 9.92122 2.4032 9.92122 2.7995Z'
      fill='white'
    />
    <path
      d='M9.92122 2.7995C9.92122 3.1958 9.59758 3.51918 9.20097 3.51918C8.80435 3.51918 8.48071 3.1958 8.48071 2.7995C8.48071 2.4032 8.80435 2.07982 9.20097 2.07982C9.60076 2.07982 9.92122 2.4032 9.92122 2.7995Z'
      fill='white'
    />
    <path
      d='M10.9874 1.01138C9.83884 -0.13631 8.2841 0.00635825 5.9996 0.00635825H5.99643C3.54058 0.00635825 1.20848 -0.2124 0.316891 2.07347C-0.0511684 3.01825 0.0027713 4.2452 0.0027713 5.99843C0.0027713 7.53925 -0.0448226 8.98813 0.316891 9.9234C1.20531 12.2124 3.55962 11.9905 5.9996 11.9905C8.35391 11.9905 10.7812 12.2346 11.6823 9.9234C12.0504 8.96911 11.9964 7.76118 11.9964 5.99843C11.9964 3.65867 12.1265 2.14638 10.9874 1.01138ZM10.8827 8.42063C10.7558 11.2201 8.62361 10.9126 6.00277 10.9126C1.22435 10.9126 1.08791 10.7762 1.08791 5.99843C1.08791 1.16673 1.46549 1.09064 5.46655 1.08747C10.5908 1.07796 11.2413 0.507283 10.8827 8.42063Z'
      fill='white'
    />
    <path
      d='M9.92122 2.7995C9.92122 3.1958 9.59758 3.51918 9.20097 3.51918C8.80435 3.51918 8.48071 3.1958 8.48071 2.7995C8.48071 2.4032 8.80435 2.07982 9.20097 2.07982C9.60076 2.07982 9.92122 2.4032 9.92122 2.7995Z'
      fill='white'
    />
    <path
      d='M9.92122 2.7995C9.92122 3.1958 9.59758 3.51918 9.20097 3.51918C8.80435 3.51918 8.48071 3.1958 8.48071 2.7995C8.48071 2.4032 8.80435 2.07982 9.20097 2.07982C9.60076 2.07982 9.92122 2.4032 9.92122 2.7995Z'
      fill='white'
    />
    <path
      d='M9.92122 2.7995C9.92122 3.1958 9.59758 3.51918 9.20097 3.51918C8.80435 3.51918 8.48071 3.1958 8.48071 2.7995C8.48071 2.4032 8.80435 2.07982 9.20097 2.07982C9.60076 2.07982 9.92122 2.4032 9.92122 2.7995Z'
      fill='white'
    />
  </svg>
)

export const Custom = () => (
  <svg
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 2C0 0.895432 0.895386 0 2 0H10C11.1046 0 12 0.895432 12 2V10C12 11.1046 11.1046 12 10 12H2C0.895386 12 0 11.1046 0 10V2ZM10.5208 10.4348H1.47888C1.1228 10.4348 0.887939 10.0738 1.03992 9.76022L3.43579 4.81522C3.59119 4.49452 4.04016 4.44844 4.26074 4.73056L6.92542 8.13887C7.10461 8.368 7.45068 8.38918 7.65784 8.1837L8.52356 7.32497C8.73657 7.11377 9.09412 7.14299 9.26794 7.38579L10.9188 9.69156C11.1432 10.005 10.9131 10.4348 10.5208 10.4348ZM7.91431 4.54419C8.80896 4.54419 9.5343 3.81892 9.5343 2.92427C9.5343 2.02962 8.80896 1.30435 7.91431 1.30435C7.01965 1.30435 6.29443 2.02962 6.29443 2.92427C6.29443 3.81892 7.01965 4.54419 7.91431 4.54419Z'
      fill='white'
    />
  </svg>
)

export const Video = () => (
  <svg
    width='20'
    height='15'
    viewBox='0 0 20 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.8068 1.00678C18.6705 1.24387 19.3523 1.92129 19.5795 2.77936C20 4.33742 20 7.58904 20 7.58904C20 7.58904 20 10.8406 19.5909 12.3987C19.3523 13.2681 18.6818 13.9455 17.8182 14.1713C16.25 14.589 10 14.589 10 14.589C10 14.589 3.75 14.589 2.19318 14.1713C1.32955 13.9342 0.647727 13.2568 0.420455 12.3987C0 10.8406 0 7.58904 0 7.58904C0 7.58904 0 4.33742 0.409091 2.77936C0.647727 1.91 1.31818 1.23258 2.18182 1.00678C3.75 0.589035 10 0.589035 10 0.589035C10 0.589035 16.25 0.589035 17.8068 1.00678ZM14.2466 7.58903L7.26025 11.5694V3.60864L14.2466 7.58903Z'
      fill='white'
    />
  </svg>
)

export const Email = () => (
  <svg
    width='18'
    height='14'
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.10713 6.76099C8.58276 7.07966 9.41732 7.07969 9.89305 6.76096C9.89312 6.76092 9.89323 6.76085 9.8933 6.76081L18 1.32957C17.7341 0.556742 17.0032 0 16.145 0H1.85508C0.99679 0 0.265936 0.556742 0 1.32957L8.10688 6.76085C8.10699 6.76092 8.10706 6.76092 8.10713 6.76099Z'
      fill='white'
    />
    <path
      d='M10.4677 8.03202C10.4676 8.03209 10.4675 8.03217 10.4675 8.0322C10.0561 8.30703 9.52795 8.44446 9 8.44446C8.47195 8.44446 7.94404 8.30706 7.5326 8.03217C7.53253 8.03213 7.5325 8.03209 7.53243 8.03206L0 3V12.0566C0 13.1282 0.870019 14 1.93944 14H16.0606C17.13 14 18 13.1282 18 12.0566V3L10.4677 8.03202Z'
      fill='white'
    />
  </svg>
)

export const SplitImage = () => (
  <svg
    width='20'
    height='14'
    viewBox='0 0 20 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5294 0H2C0.895432 0 0 0.895432 0 2V12C0 13.1046 0.895432 14 2 14H5.94118L12.5294 0ZM7.44118 14H18C19.1046 14 20 13.1046 20 12V2C20 0.895432 19.1046 0 18 0H14.0294L7.44118 14Z'
      fill='white'
    />
  </svg>
)

export const LogoPlaceholder = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.13974 13.8305C9.57478 13.9412 10.0305 14 10.5 14C13.5376 14 16 11.5376 16 8.5C16 5.46243 13.5376 3 10.5 3C10.3315 3 10.1647 3.00757 10 3.02242V4.02747C10.1642 4.00932 10.331 4 10.5 4C12.9853 4 15 6.01472 15 8.5C15 10.9853 12.9853 13 10.5 13C9.7184 13 8.98332 12.8007 8.34283 12.4502L9.13974 13.8305Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.19615 7L0 16H10.3923L5.19615 7ZM5.19615 9L1.73205 15H8.66025L5.19615 9Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 1H1V9H4V9.07181L3.4641 10H0V0H10V10H6.92821L6.35085 9H9V1Z'
      fill='white'
    />
  </svg>
)

export const Tearout = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 10H0V9H16V10Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 7L10 7L10 6L16 6L16 7Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 13H0V12H16V13Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 16H0V15H6V16Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 16H8V15H12V16Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 1H1V6H7V1ZM0 0V7H8V0H0Z'
      fill='white'
    />
  </svg>
)

export const ThreeDots = () => (
  <svg
    width='16'
    height='4'
    viewBox='0 0 16 4'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.5 2C3.5 2.828 2.828 3.5 2 3.5C1.172 3.5 0.5 2.828 0.5 2C0.5 1.172 1.172 0.5 2 0.5C2.828 0.5 3.5 1.172 3.5 2ZM9.5 2C9.5 2.828 8.828 3.5 8 3.5C7.172 3.5 6.5 2.828 6.5 2C6.5 1.172 7.172 0.5 8 0.5C8.828 0.5 9.5 1.172 9.5 2ZM14 3.5C14.828 3.5 15.5 2.828 15.5 2C15.5 1.172 14.828 0.5 14 0.5C13.172 0.5 12.5 1.172 12.5 2C12.5 2.828 13.172 3.5 14 3.5Z'
      fill='white'
    />
  </svg>
)

export const Close = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.5858 15L7.22182 21.364L8.63604 22.7782L15 16.4142L21.364 22.7782L22.7782 21.364L16.4142 15L22.7782 8.63604L21.364 7.22183L15 13.5858L8.63604 7.22183L7.22183 8.63604L13.5858 15Z'
      fill='black'
    />
  </svg>
)

export const Preferences = () => (
  <svg
    width='14'
    height='12'
    viewBox='0 0 14 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.95 2L14 2L14 3L6.95 3C6.718 4.141 5.71 5 4.5 5C3.29 5 2.282 4.141 2.05 3L-1.31134e-07 3L-8.74228e-08 2L2.05 2C2.282 0.858999 3.29 -4.68149e-07 4.5 -4.15258e-07C5.71 -3.62367e-07 6.718 0.859 6.95 2ZM4.5 4C3.672 4 3 3.328 3 2.5C3 1.672 3.672 1 4.5 1C5.328 1 6 1.672 6 2.5C6 3.328 5.328 4 4.5 4ZM-3.93402e-07 9L-4.37114e-07 10L7.05 10C7.282 11.141 8.29 12 9.5 12C10.71 12 11.718 11.141 11.95 10L14 10L14 9L11.95 9C11.718 7.859 10.71 7 9.5 7C8.29 7 7.282 7.859 7.05 9L-3.93402e-07 9ZM9.5 11C10.328 11 11 10.328 11 9.5C11 8.672 10.328 8 9.5 8C8.672 8 8 8.672 8 9.5C8 10.328 8.672 11 9.5 11Z'
      fill='white'
    />
  </svg>
)

export const TransparentBg = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5 7C11.3284 7 12 6.32843 12 5.5C12 4.67157 11.3284 4 10.5 4C9.67157 4 9 4.67157 9 5.5C9 6.32843 9.67157 7 10.5 7ZM10.5 8C11.8807 8 13 6.88071 13 5.5C13 4.11929 11.8807 3 10.5 3C9.11929 3 8 4.11929 8 5.5C8 6.88071 9.11929 8 10.5 8Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 11L6 6L0 16H16L12 10L10 11ZM11.6576 11.2892L9.72414 12.256L6.11756 7.74773L1.76619 15H14.1315L11.6576 11.2892Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 2.28571V0H2.28571V1H1V2.28571H0ZM5.71428 0V1H10.2857V0H5.71428ZM13.7143 0V1H15V2.28571H16V0H13.7143ZM16 10.2857V5.71428H15V10.2857H16ZM0 10.2857H1V5.71429H0V10.2857Z'
      fill='white'
    />
  </svg>
)
